import { getFormattedDate } from '../formatting';
import { facilityTypeValues } from '../static/sls-property-values';

import {
  dateISOValidator,
  localeValidator,
  maxLengthValidator,
  facilityTypeValidator,
  storeStatusValidator,
  countryCodeValidator,
  countryValidator,
  regionValidator,
  partnerNameValidator,
  companyValidator,
  timeHHMMFormatValidator,
} from './sls-validation';

const ABSENT_ATTRIBUTE = 'ABSENT_ATTRIBUTE';
const TEXT_FIELD_MAX_LENGTH = 40;

const getAttributeOrMarkAbsent = (data) => (attribute, extractor = (value) => value) => (data[attribute] ? extractor(data[attribute]) : ABSENT_ATTRIBUTE);
const extractAttributes = (dataFilledGetAttr) => (attributesAndExtractors) => attributesAndExtractors.reduce((acc, [attribute, extractor]) => ({ ...acc, [attribute]: dataFilledGetAttr(attribute, extractor) }), {});
// this should be an array of arrays containing functions to test the data and error messages to print if the test fails
// [[ testFunction, errorMessage], [testFunction, errorMessage], ...]
// testFunctions: (localCopy) => return true if the errorMessage should be added;
const runTests = (tests, localCopy) => tests.map(([test, message]) => (test(localCopy) ? { message } : null)).filter((error) => error);

const bulkStoreCreateValidator = (storeData, index, processedStoreList, ccmConfig) => {
  const storeDataExtractAttributes = extractAttributes(getAttributeOrMarkAbsent(storeData));
  const days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
  const attributesAndExtractorPairs = [
    ['openingDate', (date) => getFormattedDate(new Date(date))],
    ['storeStatus'],
    ['locale'],
    ['businessConcept'],
    ['email'],
    ['phone'],
    ['company'],
    ['partnerName'],
    ['postalCode'],
    ['description'],
    ['facilityType'],
    ...days.flatMap((day) => [
      [`${day}ClosedAllDay`],
      [`${day}LocalCloseTime`],
      [`${day}LocalOpenTime`],
    ]),
  ];
  const localCopy = {
    ...storeData,
    ...storeDataExtractAttributes(attributesAndExtractorPairs),
  };
  const tests = [
    // existence validation
    [({ storeNumber }) => storeNumber === ABSENT_ATTRIBUTE, `Row ${index} needs to have a storeNumber`],
    [({ country }) => country === ABSENT_ATTRIBUTE, `Row ${index} needs to have a country`],
    [({ storeName }) => storeName === ABSENT_ATTRIBUTE, `Row ${index} needs to have a storeName`],
    [({ description }) => description === ABSENT_ATTRIBUTE, `Row ${index} needs to have a description`],
    [({ facilityType }) => facilityType === ABSENT_ATTRIBUTE, `Row ${index} needs to have a facilityType`],
    [({ company }) => company === ABSENT_ATTRIBUTE, `Row ${index} needs to have a company`],
    [({ businessConcept }) => businessConcept === ABSENT_ATTRIBUTE, `Row ${index} needs to have a businessConcept`],
    [({ email }) => email === ABSENT_ATTRIBUTE, `Row ${index} needs to have a email`],
    [({ phone }) => phone === ABSENT_ATTRIBUTE, `Row ${index} needs to have a phone`],
    [({ address1 }) => address1 === ABSENT_ATTRIBUTE, `Row ${index} needs to have a address1`],
    [({ latitude }) => latitude === ABSENT_ATTRIBUTE, `Row ${index} needs to have a latitude`],
    [({ longitude }) => longitude === ABSENT_ATTRIBUTE, `Row ${index} needs to have a longitude`],
    [({ timezone }) => timezone === ABSENT_ATTRIBUTE, `Row ${index} needs to have a timezone`],
    [({ city }) => city === ABSENT_ATTRIBUTE, `Row ${index} needs to have a city`],
    [({ postalCode }) => postalCode === ABSENT_ATTRIBUTE, `Row ${index} needs to have a postalCode`],
    [({ region }) => region === ABSENT_ATTRIBUTE, `Row ${index} needs to have a region`],
    [({ locale }) => locale === ABSENT_ATTRIBUTE, `Row ${index} needs to have a locale`],
    [({ openingDate }) => openingDate === ABSENT_ATTRIBUTE, `Row ${index} needs to have a openingDate`],
    [({ storeStatus }) => storeStatus === ABSENT_ATTRIBUTE, `Row ${index} needs to have a storeStatus`],
    [({ partnerName }) => partnerName === ABSENT_ATTRIBUTE, `Row ${index} needs to have a partnerName`],

    // logical checks
    [({ openingDate }) => openingDate !== ABSENT_ATTRIBUTE && dateISOValidator(openingDate) !== '', `Row ${index} has an invalid opening date`],
    [({ storeStatus }) => storeStatus !== ABSENT_ATTRIBUTE && storeStatusValidator(storeStatus), `Row ${index} has an invalid store status`],
    [({ country }) => country !== ABSENT_ATTRIBUTE && (countryCodeValidator(country) !== '' || countryValidator(country) !== ''), `Row ${index} has invalid country. Must be 3 character country code as per the contract.`],
    [({ facilityType }) => facilityType !== ABSENT_ATTRIBUTE && facilityTypeValidator(facilityType) !== '', `Row ${index} has an invalid facilityType. Must be one of: ${facilityTypeValues.join(', ')}`],
    [({ region }) => region !== ABSENT_ATTRIBUTE && regionValidator(region) !== '', `Row ${index} has an invalid region. Must be one of the region enums in the contract`],
    [({ locale }) => (locale !== ABSENT_ATTRIBUTE && localeValidator(locale) !== ''), `Row ${index} has an invalid locale`],
    [({ company }) => company !== ABSENT_ATTRIBUTE && companyValidator(company, false, ccmConfig) !== '', `Row ${index} has an invalid company`],
    [({ partnerName }) => partnerName !== ABSENT_ATTRIBUTE && partnerNameValidator(storeData, 'partnerName', true, ccmConfig) !== '', `Row ${index} has an invalid partner name`],

    // max length validation
    [({ storeName }) => (storeName !== ABSENT_ATTRIBUTE && maxLengthValidator(storeName, TEXT_FIELD_MAX_LENGTH) !== ''), `Row ${index} has a store name that exceeds that max length 40`],
    [({ address1, address2, address3 }) => ([address1, address2, address3].some((a) => maxLengthValidator(a, TEXT_FIELD_MAX_LENGTH) !== '')), `Row ${index} has an address line that exceeds max length 40`],

    // duplicate record validation
    [({
      storeNumber, company, facilityType, country,
    }) => processedStoreList.some((store) => store.storeNumber === storeNumber && store.company === company && store.facilityType === facilityType && store.address.country === country), `Row ${index} has a duplicate StoreNumber, company, facilityType and country combination`],

    // operational hour validation
    ...days.flatMap((day) => [
      [({ [`${day}ClosedAllDay`]: closedAllDay }) => closedAllDay === ABSENT_ATTRIBUTE, `Row ${index} is missing ${day} closed all day value`],
      [({ [`${day}ClosedAllDay`]: closedAllDay }) => closedAllDay !== ABSENT_ATTRIBUTE && !['YES', 'NO'].includes(closedAllDay), `Row ${index} has an invalid value for ${day} closed all day, valid values are YES or NO`],
      [({ [`${day}ClosedAllDay`]: closedAllDay, [`${day}LocalOpenTime`]: localOpenTime }) => closedAllDay === 'NO' && localOpenTime === ABSENT_ATTRIBUTE, `Row ${index} is missing ${day} local open time, which is required for "closed all day" data value of "NO"`],
      [({ [`${day}ClosedAllDay`]: closedAllDay, [`${day}LocalCloseTime`]: localCloseTime }) => closedAllDay === 'NO' && localCloseTime === ABSENT_ATTRIBUTE, `Row ${index} is missing ${day} local close time, which is required for "closed all day" data value of "NO"`],
      [({ [`${day}ClosedAllDay`]: closedAllDay, [`${day}LocalOpenTime`]: localOpenTime }) => closedAllDay === 'NO' && localOpenTime !== ABSENT_ATTRIBUTE && !timeHHMMFormatValidator(localOpenTime), `Row ${index} has an invalid ${day} local open time, must be in HH:MM format`],
      [({ [`${day}ClosedAllDay`]: closedAllDay, [`${day}LocalCloseTime`]: localCloseTime }) => closedAllDay === 'NO' && localCloseTime !== ABSENT_ATTRIBUTE && !timeHHMMFormatValidator(localCloseTime), `Row ${index} has an invalid ${day} local close time, must be in HH:MM format`],
    ]),
  ];
  const errors = runTests(tests, localCopy);

  return { errors, validatedStoreData: localCopy };
};

export default bulkStoreCreateValidator;
