import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import edsCss from '../styles/eds.css.js';
import hierarchyCss from '../styles/store-hierarchy.css.js';

import StoreHierarchyBody from './StoreHierarchyBody';
import StoreHierarchyHeader from './StoreHierarchyHeader';

const StoreHierarchyPage = ({ isScheduling, scheduleList, scheduleEditList }) => {
  useEffect(() => {
    const style = document.createElement('style');
    style.textContent = `${edsCss}\n${hierarchyCss}`;
    document.head.appendChild(style);
    return () => {
      document.head.removeChild(style);
    };
  }, []);

   return (
     <div className="store-hierarchy-page">
       <StoreHierarchyHeader />
       <StoreHierarchyBody isScheduling={isScheduling} scheduleEditList={scheduleEditList} scheduleList={scheduleList} />
     </div>
   );
};

StoreHierarchyPage.propTypes = {
  isScheduling: PropTypes.bool.isRequired,
  scheduleEditList: PropTypes.arrayOf(
    PropTypes.shape({
      brand: PropTypes.string.isRequired,
      createdAt: PropTypes.string.isRequired,
      createdBy: PropTypes.string.isRequired,
      eventName: PropTypes.string.isRequired,
      groupId: PropTypes.string.isRequired,
      payload: PropTypes.shape({}).isRequired,
      region: PropTypes.string.isRequired,
      scheduledDate: PropTypes.string.isRequired,
      scheduleId: PropTypes.string.isRequired,
      scheduleStatus: PropTypes.string.isRequired
    })
  ).isRequired,
  scheduleList: PropTypes.arrayOf(
    PropTypes.shape({
      brand: PropTypes.string.isRequired,
      createdAt: PropTypes.string.isRequired,
      createdBy: PropTypes.string.isRequired,
      eventName: PropTypes.string.isRequired,
      groupId: PropTypes.string.isRequired,
      payload: PropTypes.shape({}).isRequired,
      region: PropTypes.string.isRequired,
      scheduledDate: PropTypes.string.isRequired,
      scheduleId: PropTypes.string.isRequired,
      scheduleStatus: PropTypes.string.isRequired
    })
  ).isRequired,
};

export default StoreHierarchyPage;
