//
// Set useMockHierarchyService to true to use the mock service instead of the live one.
//
const useMockHierarchyService = false;

//
// Set useMockStoreService to true to use the mock service instead of the live one.
//
const useMockStoresService = false;

export default () => ({
    useMockHierarchyService,
    useMockStoresService,
});
