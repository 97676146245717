import {
  Button, ButtonGroup, TextField, TextGroup
} from '@nike/eds';
import {
  Check, Close, Delete
} from '@nike/nike-design-system-icons';
import { isSome } from '@nike/rcf-fp';
import { find } from 'lodash';
import PropTypes from 'prop-types';
import React, {
  useContext, useEffect, useMemo, useState
} from 'react';
import { v4 as uuidv4 } from 'uuid';

import { deleteNode, updateNode, createNode } from '../../services/hierarchy-service';
import { isChildOf } from '../../utils/hierarchy-utils';
import { userCanDeleteTerritory, userCanEditTerritory, userHasWritePermissionForRegion } from '../../utils/permissions';
import { isNotWhitespaceOrNil } from '../../utils/string-utils';
import { Modal, Select } from '../eds-custom';
import { StoreHierarchyContext } from '../StoreHierarchyContextProvider';

import AddNodeButton from './common/AddNodeButton';
import HierarchyColumnTitle from './common/HierarchyColumnTitle';
import HierarchyNode from './common/HierarchyNode';

const Territories = ({ isScheduling, scheduleList, scheduleEditList }) => {
  const {
    accessToken,
    currentSheduleChangesList,
    setEditList,
    selectedRegion = {},
    hierarchyNodes = [],
    selectedTerritory,
    userGroups,
    hierarchyNodeLookupTable,
    selectedBrand,
    setCurrentSheduleChangesList,
    setIsPageLoading,
    setHierarchyNodes,
    setError,
    setSuccessMessage,
    setActiveNodeId,
    user
  } = useContext(StoreHierarchyContext);

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editModalName, setEditModalName] = useState(selectedTerritory?.name ?? '');
  const [editModalParentId, setEditModalParentId] = useState(selectedTerritory?.parentId ?? '');
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [addModalName, setAddModalName] = useState('');
  const [continueEdit, setContinueEdit] = useState(false);
  const [curatedTerritories, setcuratedTerritories] = useState([]);

  // Update editModalName and newParentRegion when selectedTerritory changes
  useEffect(() => {
    setEditModalName(selectedTerritory?.name ?? '');
    setEditModalParentId(selectedTerritory?.parentId ?? '');
  }, [selectedTerritory]);

  // Reset addModalName and addModalParentId when isAddModalOpen changes
  useEffect(() => {
    setAddModalName('');
  }, [isAddModalOpen]);

  // function to return latest district for the store
  function findInScheduledTerritories(district) {
    if (scheduleList && scheduleList.length > 0) {
      const storeInSchedule = scheduleList.find((schedule) => schedule.payload.id === district.id);
      if (storeInSchedule) {
        return storeInSchedule.destinationLocation;
      }
    }
    return null;
  }

  const regions = useMemo(() => hierarchyNodes.filter(isChildOf(selectedBrand)), [hierarchyNodes, selectedBrand]);
  const territories = useMemo(() => hierarchyNodes.filter(isChildOf(selectedRegion)), [hierarchyNodes, selectedRegion]);
  const parentSelectValue = useMemo((newParent = hierarchyNodeLookupTable?.[editModalParentId] ?? {}) => ({ label: newParent?.name, value: newParent?.id }), [hierarchyNodeLookupTable, editModalParentId]);
  const parentSelectOptions = useMemo(() => regions.map(({ id, name }) => ({ label: name, value: id })), [regions]);
  const isDirtyEditModalName = useMemo(() => editModalName !== selectedTerritory?.name, [editModalName, selectedTerritory]);
  const isDirtyEditModalParentId = useMemo(() => isNotWhitespaceOrNil(editModalParentId) && editModalParentId !== selectedTerritory?.parentId, [editModalParentId, selectedTerritory]);
  const isDirtyForEdit = useMemo(() => isDirtyEditModalName || isDirtyEditModalParentId, [isDirtyEditModalName, isDirtyEditModalParentId]);
  const isDuplicateNameForEdit = useMemo(() => hierarchyNodes.some((node) => node.name === editModalName
      && node.nodeType === 'TERRITORY'
      && node.parentId === editModalParentId
      && node.id !== selectedTerritory?.id),
    [hierarchyNodes, editModalName, editModalParentId, selectedTerritory]);
    const isDuplicateNameForScheduleEdit = useMemo(() => scheduleEditList && scheduleEditList.some((node) => node.payload.name === editModalName
    && node.payload.nodeType === 'TERRITORY'
    && node.payload.parentId === editModalParentId
    && node.payload.id !== selectedTerritory?.id),
  [scheduleEditList, editModalName, editModalParentId, selectedTerritory]);
  const isValidNameForEdit = useMemo(() => !isDuplicateNameForEdit && !isDuplicateNameForScheduleEdit && isNotWhitespaceOrNil(editModalName), [isDuplicateNameForEdit, isDuplicateNameForScheduleEdit, editModalName]);
  const isValidForEdit = useMemo(() => isValidNameForEdit && isNotWhitespaceOrNil(editModalParentId), [editModalParentId, isValidNameForEdit]);
  const DUPLICATE_NAME_ERROR_MESSAGE = 'A territory with this name already exists.';
  const editModalNameErrorMessage = useMemo(() => ((isDuplicateNameForEdit || isDuplicateNameForScheduleEdit) ? DUPLICATE_NAME_ERROR_MESSAGE : ''), [isDuplicateNameForEdit, isDuplicateNameForScheduleEdit]);
  const isDuplicateNameForAdd = useMemo(() => hierarchyNodes.some((node) => node.name === addModalName
      && node.nodeType === 'TERRITORY'
      && node.parentId === selectedRegion?.id),
    [hierarchyNodes, addModalName, selectedRegion]);
  const isDuplicateNameForScheduleAdd = useMemo(() => scheduleEditList && scheduleEditList.some((node) => node.payload.name === addModalName
    && node.payload.nodeType === 'TERRITORY'
    && node.payload.parentId === selectedRegion?.id),
  [scheduleEditList, addModalName, selectedRegion]);
  const isValidForAdd = useMemo(() => isNotWhitespaceOrNil(addModalName) && !isDuplicateNameForAdd && !isDuplicateNameForScheduleAdd, [addModalName, isDuplicateNameForAdd, isDuplicateNameForScheduleAdd]);
  const addModalErrorMessage = useMemo(() => ((isDuplicateNameForAdd || isDuplicateNameForScheduleAdd) ? DUPLICATE_NAME_ERROR_MESSAGE : ''), [isDuplicateNameForAdd, isDuplicateNameForScheduleAdd]);
  const actionComponent = useMemo(() => (
    isSome(selectedRegion)
    && userHasWritePermissionForRegion(userGroups, hierarchyNodeLookupTable, selectedRegion)
    && (<AddNodeButton label="Add Territory" onClick={() => setIsAddModalOpen(true)} />)
  ), [userGroups, hierarchyNodeLookupTable, selectedRegion]);

  const createScheduleChangeModel = (individualSelectedTerritorry, selectectedterritoryName, currentOperation, uuId) => ({
    brand: selectedBrand.name,
    createdBy: scheduleEditList && scheduleEditList.length > 0 ? scheduleEditList[0].createdBy : user,
    destinationLocation: currentOperation !== 'DELETION' ? find(hierarchyNodes, { id: individualSelectedTerritorry.parentId }).name : '',
    entityId: individualSelectedTerritorry.id === undefined ? uuId : individualSelectedTerritorry.id,
    entityType: 'Territory',
    eventName: '',
    existingLocation: selectectedterritoryName,
    groupId: '',
    modifiedBy: user,
    operation: currentOperation,
    payload: {
      id: individualSelectedTerritorry.id === undefined ? uuId : individualSelectedTerritorry.id,
      name: individualSelectedTerritorry.name,
      nodeType: individualSelectedTerritorry.nodeType,
      parentId: individualSelectedTerritorry.parentId
    },
    region: selectedRegion.name,
    scheduledDate: '',
    scheduleId: null,
    scheduleStatus: ''
  });

    useEffect(() => {
      if (!isScheduling) {
        setContinueEdit(true);
      }
    }, [isScheduling]);

  useEffect(() => {
    const updatedTerritories = [...territories];
    if (updatedTerritories && scheduleEditList && selectedRegion && updatedTerritories !== null && scheduleEditList !== null && selectedRegion !== null) {
      // find the district in the scheduleEditList and update the districts
      updatedTerritories.forEach((territorry) => {
        // schedule.payload.id should not be equal to the selectedTerritory.id
        const territorryInSchedule = scheduleEditList.find((schedule) => schedule.payload.id === territorry.id && schedule.payload.parentId !== selectedRegion.id);
        // remove store from the districtStores
        if (territorryInSchedule) {
          const index = updatedTerritories.findIndex((d) => d.id === territorryInSchedule.payload.id);
          if (index !== -1) {
            updatedTerritories.splice(index, 1);
          }
        }
      });
      scheduleEditList.forEach((schedule) => {
         if (schedule.payload.parentId === selectedRegion.id && schedule.operation !== 'DELETION') {
          schedule.payload.parentId = selectedRegion.id;
          const index = updatedTerritories.findIndex((d) => d.id === schedule.payload.id);
          if (index !== -1) {
            updatedTerritories[index] = schedule.payload;
          } else {
            updatedTerritories.push(schedule.payload);
          }
        }
      });
      setcuratedTerritories(updatedTerritories);
    } else {
      setcuratedTerritories(territories);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [territories, scheduleEditList, selectedRegion]);

   // handle continue edit flow
   const handleContinueEdit = () => {
    setContinueEdit(true);
  };

  // Set EditList to scheduleEditList so that in contextHierarchyProvider we can decide the effective territory and region based on the scheduledEditList
 const handleOnDistrictClick = (territorry) => {
    if (scheduleEditList && scheduleEditList.length > 0) {
        setEditList(scheduleEditList);
    }
    setActiveNodeId(territorry.id);
  };

  return (
    <>
      {isEditModalOpen && (
        <Modal
          footerSlot={(isScheduling && findInScheduledTerritories(selectedTerritory) !== null && continueEdit) || (isScheduling && findInScheduledTerritories(selectedTerritory) == null && !continueEdit) ? (
            <ButtonGroup className="eds-flex eds-flex--direction-row eds-flex--justify-content-space-between">
              <Button
                afterSlot={<Check />}
                disabled={!(isDirtyForEdit && isValidForEdit)}
                size="small"
                variant="primary"
                onClick={() => {
                  const updatedTerritory = { ...selectedTerritory, name: editModalName, parentId: editModalParentId };
                  // add the territorry change to the currentSheduleChangesList
                  const currentSheduleChangesListCopy = Array.isArray(currentSheduleChangesList) ? [...currentSheduleChangesList] : [];
                  const storeIndex = currentSheduleChangesListCopy.findIndex((district) => district.entityId === updatedTerritory.id);
                  const scheduleChangeModel = createScheduleChangeModel(updatedTerritory, selectedRegion.name, 'MIGRATION', null);
                  if (storeIndex === -1) {
                    currentSheduleChangesListCopy.push(scheduleChangeModel);
                  } else {
                    currentSheduleChangesListCopy[storeIndex] = scheduleChangeModel;
                  }
                  setCurrentSheduleChangesList(currentSheduleChangesListCopy);
                  setIsEditModalOpen(false);
                }}
              >Apply for Scheduling
              </Button>
              <Button
                afterSlot={<Delete />}
                disabled={!userCanDeleteTerritory(userGroups, hierarchyNodeLookupTable, selectedTerritory)}
                size="small"
                variant="secondary"
                onClick={() => {
                  // eslint-disable-next-line no-alert
                  const confirmDelete = window.confirm(`Delete territory ${selectedTerritory?.name}?`);
                  if (confirmDelete) {
                    const updatedTerritory = { ...selectedTerritory, name: editModalName, parentId: editModalParentId };
                    // add the territorry change to the currentSheduleChangesList
                    const currentSheduleChangesListCopy = Array.isArray(currentSheduleChangesList) ? [...currentSheduleChangesList] : [];
                    const storeIndex = currentSheduleChangesListCopy.findIndex((district) => district.entityId === updatedTerritory.id);
                    const scheduleChangeModel = createScheduleChangeModel(updatedTerritory, selectedRegion.name, 'DELETION', null);
                    if (storeIndex === -1) {
                      currentSheduleChangesListCopy.push(scheduleChangeModel);
                    }
                    setCurrentSheduleChangesList(currentSheduleChangesListCopy);
                    setIsEditModalOpen(false);
                  }
                }}
              >Delete
              </Button>
              <Button afterSlot={<Close />} size="small" variant="secondary" onClick={() => setIsEditModalOpen(false)}>Close</Button>
            </ButtonGroup>
          ) : continueEdit && (
            <ButtonGroup className="eds-flex eds-flex--direction-row eds-flex--justify-content-space-between">
              <Button
                afterSlot={<Check />}
                disabled={!(isDirtyForEdit && isValidForEdit)}
                size="small"
                variant="primary"
                onClick={() => {
                  setIsPageLoading(true);
                  const updatedTerritory = { ...selectedTerritory, name: editModalName, parentId: editModalParentId };
                  // eslint-disable-next-line promise/catch-or-return
                  updateNode(updatedTerritory, accessToken)
                    .then(async (newNodes) => {
                      await setHierarchyNodes(newNodes);
                      setActiveNodeId(updatedTerritory.id);
                      setSuccessMessage(`Territory ${updatedTerritory.name} updated.`);
                      return newNodes;
                    })
                    .catch(setError)
                    .finally(() => setIsPageLoading(false));
                }}
              >Save
              </Button>
              <Button
                afterSlot={<Delete />}
                disabled={!userCanDeleteTerritory(userGroups, hierarchyNodeLookupTable, selectedTerritory)}
                size="small"
                variant="secondary"
                onClick={() => {
                  // eslint-disable-next-line no-alert
                  const confirmDelete = window.confirm(`Delete territory ${selectedTerritory?.name}?`);
                  if (confirmDelete) {
                    setIsPageLoading(true);
                    // eslint-disable-next-line promise/catch-or-return
                    deleteNode(selectedTerritory, accessToken)
                      .then(async (newNodes) => {
                        await setHierarchyNodes(newNodes);
                        setSuccessMessage(`Territory ${selectedTerritory.name} deleted.`);
                        setActiveNodeId(selectedTerritory.parentId);
                        return newNodes;
                      })
                      .catch(setError)
                      .finally(() => {
                        setIsPageLoading(false);
                      });
                  }
                }}
              >Delete
              </Button>
              <Button afterSlot={<Close />} size="small" variant="secondary" onClick={() => setIsEditModalOpen(false)}>Close</Button>
            </ButtonGroup>
          )}
          headerSlot="Edit Territory"
          isOpen={isEditModalOpen}
          onDismiss={() => setIsEditModalOpen(false)}
        >
          {findInScheduledTerritories(selectedTerritory) !== null && !continueEdit && (
          <>
            <p style={{ color: 'orange' }}>Warning! There is already a scheduled event for this territory! <br />
              {findInScheduledTerritories(selectedTerritory) ? `The territory is scheduled to move to ${findInScheduledTerritories(selectedTerritory)}.` : 'This territory is scheduled to be deleted.'}
            </p>
            <br />
            <p>Do you want to continue editing ?</p>
            <br />
            <Button size="small" variant="secondary" onClick={handleContinueEdit}> Continue </Button>
          </>
            )}
            {((findInScheduledTerritories(selectedTerritory) !== null && continueEdit) || (findInScheduledTerritories(selectedTerritory) == null && !continueEdit) || (!isScheduling)) && (
            <TextGroup>
              <TextField
                errorMessage={editModalNameErrorMessage}
                hasErrors={isDuplicateNameForEdit || isDuplicateNameForScheduleEdit}
                label="Name"
                value={editModalName}
                onChange={(e) => setEditModalName(e.target.value)}
              />
              <Select
                id="region-select"
                label="Region"
                options={parentSelectOptions}
                value={parentSelectValue}
                onChange={(e) => setEditModalParentId(e?.value)}
              />
            </TextGroup>
          )}
        </Modal>
      )}
      {isAddModalOpen && (
        <Modal
          footerSlot={isScheduling ? (
            <ButtonGroup className="eds-flex eds-flex--direction-row eds-flex--justify-content-space-between">
              <Button
                afterSlot={<Check />}
                disabled={!isValidForAdd}
                size="small"
                variant="primary"
                onClick={() => {
                  const newTerritory = { name: addModalName, nodeType: 'TERRITORY', parentId: selectedRegion?.id };
                  // add the territorry change to the currentSheduleChangesList
                  const currentSheduleChangesListCopy = Array.isArray(currentSheduleChangesList) ? [...currentSheduleChangesList] : [];
                  const storeIndex = currentSheduleChangesListCopy.findIndex((district) => district.entityId === newTerritory.id);
                  const scheduleChangeModel = createScheduleChangeModel(newTerritory, null, 'CREATION', uuidv4());
                  if (storeIndex === -1) {
                    currentSheduleChangesListCopy.push(scheduleChangeModel);
                  } else {
                    currentSheduleChangesListCopy[storeIndex] = scheduleChangeModel;
                  }
                  setCurrentSheduleChangesList(currentSheduleChangesListCopy);
                  setIsAddModalOpen(false);
                }}
              >Apply for Scheduling
              </Button>
              <Button afterSlot={<Close />} size="small" variant="secondary" onClick={() => setIsAddModalOpen(false)}>Close</Button>
            </ButtonGroup>
          ) : (
            <ButtonGroup className="eds-flex eds-flex--direction-row eds-flex--justify-content-space-between">
              <Button
                afterSlot={<Check />}
                disabled={!isValidForAdd}
                size="small"
                variant="primary"
                onClick={() => {
                setIsPageLoading(true);
                const newTerritory = { name: addModalName, nodeType: 'TERRITORY', parentId: selectedRegion?.id };
                // eslint-disable-next-line promise/catch-or-return
                createNode(newTerritory, accessToken)
                  .then((newNodes) => {
                    setHierarchyNodes(newNodes);
                    const savedNewTerritory = newNodes.find((node) => node.parentId === newTerritory.parentId
                      && node.name === newTerritory.name
                      && node.nodeType === newTerritory.nodeType);
                    setActiveNodeId(savedNewTerritory.id);
                    setSuccessMessage(`Territory ${newTerritory.name} added.`);
                    return newTerritory;
                  })
                  .catch(setError)
                  .finally(() => {
                    setIsPageLoading(false);
                    setIsAddModalOpen(false);
                  });
              }}
              >Add
              </Button>
              <Button afterSlot={<Close />} size="small" variant="secondary" onClick={() => setIsAddModalOpen(false)}>Close</Button>
            </ButtonGroup>
 )}
          headerSlot="Add Territory"
          isOpen={isAddModalOpen}
          onDismiss={() => setIsAddModalOpen(false)}
        >
          <TextGroup>
            <TextField
              errorMessage={addModalErrorMessage}
              hasErrors={isDuplicateNameForAdd || isDuplicateNameForScheduleAdd}
              label="Name"
              value={addModalName}
              onChange={(e) => setAddModalName(e.target.value)}
            />
            <TextField
              disabled
              label="Region"
              value={selectedRegion?.name}
            />
          </TextGroup>
        </Modal>
      )}
      <HierarchyColumnTitle actionComponent={actionComponent} title="Territories" />
      {
        curatedTerritories && curatedTerritories.map((territory) => (
          <HierarchyNode
            key={territory.id}
            hierarchyNode={territory}
            isDeletable={userCanDeleteTerritory(userGroups, hierarchyNodeLookupTable, territory)}
            isEditable={userCanEditTerritory(userGroups, hierarchyNodeLookupTable, territory)}
            isSelected={territory.id === selectedTerritory?.id}
            onClick={() => {
              handleOnDistrictClick(territory);
            }}
            onEdit={() => setIsEditModalOpen(true)}
          />
        ))
      }
    </>
  );
};

Territories.propTypes = {
  isScheduling: PropTypes.bool.isRequired,
  scheduleEditList: PropTypes.arrayOf(
    PropTypes.shape({
      brand: PropTypes.string.isRequired,
      createdAt: PropTypes.string.isRequired,
      createdBy: PropTypes.string.isRequired,
      eventName: PropTypes.string.isRequired,
      groupId: PropTypes.string.isRequired,
      payload: PropTypes.shape({}).isRequired,
      region: PropTypes.string.isRequired,
      scheduledDate: PropTypes.string.isRequired,
      scheduleId: PropTypes.string.isRequired,
      scheduleStatus: PropTypes.string.isRequired
    })
  ).isRequired,
  scheduleList: PropTypes.arrayOf(
    PropTypes.shape({
      brand: PropTypes.string.isRequired,
      createdAt: PropTypes.string.isRequired,
      createdBy: PropTypes.string.isRequired,
      eventName: PropTypes.string.isRequired,
      groupId: PropTypes.string.isRequired,
      payload: PropTypes.shape({}).isRequired,
      region: PropTypes.string.isRequired,
      scheduledDate: PropTypes.string.isRequired,
      scheduleId: PropTypes.string.isRequired,
      scheduleStatus: PropTypes.string.isRequired
    })
  ).isRequired,
};
export default Territories;
