import { Input } from '@nike/frame-component-library';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';

import {
  countryOptions,
  countryValues,
} from '../../../../utils/static/sls-property-values';
import { ButtonRed, Select } from '../../../reusable';

import Announcements from './Announcements';

const AdditionalLocalizations = (props) => {
  const showSubLocality = props.country === 'THA' && props.region === 'ASIA_PACIFIC_LATIN_AMERICA';
  return (
    <>
      <aside className="ncss-col-sm-3 va-sm-t" />
      <header className="ncss-col-sm-6 va-sm-t ta-sm-c mt2-sm">
        <span className="body-1">Language</span>
        <Select
          id="language"
          isDisabled={props.userIsReadOnly}
          label=""
          options={Object.values(props.remainingLanguages).map((val) => ({ label: `${val[0]}-${val[1]}`, value: val[0] }))}
          value={props.localization.language || ''}
          zIndex={24}
          onChange={(value) => props.updateLocalization(props.index, 'language', value)}
        />
      </header>
      <aside className="ncss-col-sm-3 va-sm-t ta-sm-r">
        <ButtonRed className="mt8-sm" isDisabled={props.userIsReadOnly} label="Delete Localization" onClick={() => props.removeLocalization(props.index)} />
      </aside>
      <section className="ncss-col-sm-6 va-sm-t">
        <Input
          errorMessage={props.localizationErrors.address1}
          isDisabled={props.userIsReadOnly}
          label="Address Line 1"
          maxLength="40"
          value={props.localization.address.address1 || ''}
          onChange={({ target: { value: address1 } }) => props.updateAddress(props.index, 'address1', address1)}
        />
        <Input
          errorMessage={props.localizationErrors.address2}
          isDisabled={props.userIsReadOnly}
          label="Address Line 2"
          maxLength="40"
          value={props.localization.address.address2 || ''}
          onChange={({ target: { value: address2 } }) => props.updateAddress(props.index, 'address2', address2)}
        />
        <Input
          errorMessage={props.localizationErrors.address3}
          isDisabled={props.userIsReadOnly}
          label="Address Line 3"
          maxLength="40"
          value={props.localization.address.address3 || ''}
          onChange={({ target: { value: address3 } }) => props.updateAddress(props.index, 'address3', address3)}
        />
        {!showSubLocality && (
        <Select
          isDisabled={props.userIsReadOnly}
          label="Country"
          options={[{ label: '', value: '' }].concat(countryOptions)}
          value={props.localization.address.country || ''}
          zIndex="23"
          onChange={(value) => { props.updateAddressFields(props.index, { country: value, iso2Country: countryValues[value] }); }}
        />
      )}
        {showSubLocality && (
        <Input
          isDisabled={props.userIsReadOnly}
          label="Sublocality"
          value={props.localization.address.subLocality || ''}
          onChange={({ target: { value: subLocality } }) => props.updateAddress(props.index, 'subLocality', subLocality)}
        />
      )}
      </section>
      <section className="ncss-col-sm-6 va-sm-t">
        <Input
          isDisabled={props.userIsReadOnly}
          label="Store Name"
          value={props.localization.address.storeName || ''}
          onChange={({ target: { value: storeName } }) => props.updateAddress(props.index, 'storeName', storeName)}
        />
        <Input
          errorMessage={props.localizationErrors.city}
          isDisabled={props.userIsReadOnly}
          label="City"
          value={props.localization.address.city || ''}
          onChange={({ target: { value: city } }) => props.updateAddress(props.index, 'city', city)}
        />
        <section className="ncss-row">
          <article className="ncss-col-sm-6 va-sm-t">
            <Input
              isDisabled={props.userIsReadOnly}
              label="State"
              value={props.localization.address.state || ''}
              onChange={({ target: { value: state } }) => props.updateAddress(props.index, 'state', state)}
            />
          </article>
          <article className="ncss-col-sm-6 va-sm-t">
            <Input
              isDisabled={props.userIsReadOnly}
              label="Postal Code"
              value={props.localization.address.postalCode || ''}
              onChange={({ target: { value: postalCode } }) => props.updateAddress(props.index, 'postalCode', postalCode)}
            />
          </article>
        </section>
        {showSubLocality && (
        <Select
          isDisabled={props.userIsReadOnly}
          label="Country"
          options={[{ label: '', value: '' }].concat(countryOptions)}
          value={props.localization.address.country || ''}
          zIndex="23"
          onChange={(value) => { props.updateAddressFields(props.index, { country: value, iso2Country: countryValues[value] }); }}
        />
      )}
      </section>
      <article className="ncss-col-sm-12 mt4-sm">
        <header className="body-1">Contextual Address</header>
        <aside style={{ borderTop: '2px solid' }} />
        <section className="ncss-row">
          <article className="ncss-col-sm-6 va-sm-t">
            <Input
              isDisabled={props.userIsReadOnly}
              label="Direction"
              value={props.localization.contextualAddress?.address || ''}
              onChange={({ target: { value: address } }) => props.updateContextualAddress(props.index, 'address', address)}
            />
          </article>
          <article className="ncss-col-sm-6 va-sm-t">
            <Input
              isDisabled={props.userIsReadOnly}
              label="Neighborhood"
              value={props.localization.contextualAddress?.neighborhood || ''}
              onChange={({ target: { value: neighborhood } }) => props.updateContextualAddress(props.index, 'neighborhood', neighborhood)}
            />
          </article>
          <article className="ncss-col-sm-6 va-sm-t">
            <Input
              isDisabled={props.userIsReadOnly}
              label="Transit"
              value={props.localization.contextualAddress?.transit || ''}
              onChange={({ target: { value: transit } }) => props.updateContextualAddress(props.index, 'transit', transit)}
            />
          </article>
          <article className="ncss-col-sm-6 va-sm-t">
            <Input
              isDisabled={props.userIsReadOnly}
              label="Parking Suggestions"
              value={props.localization.contextualAddress?.parkingSuggestions || ''}
              onChange={({ target: { value: parkingSuggestions } }) => props.updateContextualAddress(props.index, 'parkingSuggestions', parkingSuggestions)}
            />
          </article>
        </section>
      </article>
      <Announcements
        announcements={props.localization.announcements || []}
        currentTime={moment.tz(new Date(), props.timezone).format('YYYY-MM-DD')}
        errors={props.localizationErrors.announcements || ''}
        popAnnouncement={(indexToPop) => {
        const announcements = props.localization.announcements ? [...props.localization.announcements] : [];
        announcements.splice(indexToPop, 1);
        props.updateLocalization(props.index, 'announcements', announcements);
      }}
        pushAnnouncement={() => {
        const announcements = props.localization.announcements ? [...props.localization.announcements] : [];
        const newAnnouncement = {
          content: '', expiryDate: '', messageType: 'ALERT', newEdit: true,
        };
        announcements.push(newAnnouncement);
        props.updateLocalization(props.index, 'announcements', announcements);
      }}
        updateAnnouncements={(index, announcement, value, newEdit, key) => {
        const announcements = props.localization.announcements ? [...props.localization.announcements] : [];
        announcements[index] = {
          ...announcement, newEdit,
        };
        announcements[index][key] = value;
        props.updateLocalization(props.index, 'announcements', announcements);
      }}
        userIsReadOnly={props.userIsReadOnly}
      />
      <aside className="mt2-sm" style={{ borderTop: '4px solid' }} />
    </>
  );
};

AdditionalLocalizations.propTypes = {
  country: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  localization: PropTypes.shape().isRequired,
  localizationErrors: PropTypes.shape().isRequired,
  region: PropTypes.string.isRequired,
  remainingLanguages: PropTypes.shape().isRequired,
  removeLocalization: PropTypes.func.isRequired,
  timezone: PropTypes.string.isRequired,
  updateAddress: PropTypes.func.isRequired,
  updateAddressFields: PropTypes.func.isRequired,
  updateContextualAddress: PropTypes.func.isRequired,
  updateLocalization: PropTypes.func.isRequired,
  userIsReadOnly: PropTypes.bool.isRequired,
};

export default AdditionalLocalizations;
