import React from 'react';

import { storeHierarchyPermissions } from '../../../utils/tab-permissions';
import { PageTemplatePlain as PageTemplate } from '../../reusable';

import StoreHierarchyScheduleMain from './StoreHierarchyScheduleMain';

const StoreHierarchyPage = () => (
  <PageTemplate
    auth={storeHierarchyPermissions}
    description="Manage Districts and Territories for Stores"
    help={(
      <a
        className="ncss-cta-primary-dark underline text-color-secondary"
        href="https://confluence.nike.com/display/RCFITC/RCF+KB+-+Store+Hierarchy+UI"
        rel="noopener noreferrer"
        target="_blank"
      >
        Click here to view the KB
      </a>
    )}
    page={(
      <StoreHierarchyScheduleMain />
    )}
    title="Store Hierarchy Scheduleing"
  />
);

export default StoreHierarchyPage;
