import {
  Button,
  ButtonGroup,
  TextField,
  TextGroup,
  Toggle
} from '@nike/eds';
import { Check, Close } from '@nike/nike-design-system-icons';
import {
  Default, isNone, isSome, match
} from '@nike/rcf-fp';
import PropTypes from 'prop-types';
import React, {
  useContext, useEffect, useMemo, useRef, useState
} from 'react';

import { getRegionStores, updateStore } from '../../services/stores-service';
import { isChildOf } from '../../utils/hierarchy-utils';
import { userHasWritePermissionForRegion } from '../../utils/permissions';
import { getStoreDisplayName } from '../../utils/store-utils';
import { isNotWhitespaceOrNil, isWhitespaceOrNil } from '../../utils/string-utils';
import { Modal, Select } from '../eds-custom';
import { StoreHierarchyContext } from '../StoreHierarchyContextProvider';

import AddNodeButton from './common/AddNodeButton';
import HierarchyColumnTitle from './common/HierarchyColumnTitle';
import HierarchyNode from './common/HierarchyNode';

const Stores = ({ isScheduling, scheduleList, scheduleEditList }) => {
  const {
    activeNodeId,
    currentSheduleChangesList,
    districtStores,
    selectedBrand,
    selectedStore,
    setSelectedStore,
    selectedDistrict,
    selectedRegion,
    hierarchyNodeLookupTable,
    userGroups,
    setIsPageLoading,
    setError,
    hierarchyNodes,
    setSuccessMessage,
    setCurrentSheduleChangesList,
    selectedTerritory,
    setSelectedDistrict,
    setSelectedTerritory,
    accessToken,
    user
  } = useContext(StoreHierarchyContext);

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editModalGrandparentId, setEditModalGrandparentId] = useState(selectedDistrict?.parentId ?? '');
  const [editModalParentId, setEditModalParentId] = useState(selectedDistrict?.id ?? '');
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [addModalStore, setAddModalStore] = useState(null);
  const [regionStores, setRegionStores] = useState([]);
  const [isOnlyOrphanStores, setIsOnlyOrphanStores] = useState(true);
  const [continueEdit, setContinueEdit] = useState(false);
  const [curatedDistrictStores, setCuratedDistrictStores] = useState([]);

  const territories = useMemo(() => (isSome(selectedRegion)
    ? hierarchyNodes.filter(isChildOf(selectedRegion))
    : []), [hierarchyNodes, selectedRegion]);
  const districts = useMemo(() => (isNotWhitespaceOrNil(editModalGrandparentId)
    ? hierarchyNodes.filter(isChildOf(hierarchyNodeLookupTable?.[editModalGrandparentId]))
    : []), [editModalGrandparentId, hierarchyNodes, hierarchyNodeLookupTable]);
  const noneOption = { label: 'None', value: '' };
  const grandparentSelectOptions = useMemo(() => territories.map(({ name, id }) => ({ label: name, value: id })), [territories]);
  const parentSelectOptions = useMemo(() => [noneOption, ...districts.map(({ id, name }) => ({ label: name, value: id }))], [noneOption, districts]);
  const grandparentSelectValue = useMemo(
    (newGrandparent = hierarchyNodeLookupTable?.[editModalGrandparentId] ?? {}) => (isWhitespaceOrNil(editModalGrandparentId)
      ? noneOption
      : { label: newGrandparent?.name, value: newGrandparent?.id }),
    [editModalGrandparentId, hierarchyNodeLookupTable, noneOption]
  );
  const parentSelectValue = useMemo((
    newParent = hierarchyNodeLookupTable?.[editModalParentId] ?? {},
  ) => (isWhitespaceOrNil(editModalParentId) || !parentSelectOptions.some((option) => option.value === editModalParentId)
    ? noneOption
    : { label: newParent?.name, value: newParent?.id }),
    [editModalParentId, hierarchyNodeLookupTable, noneOption, parentSelectOptions]);
  const isDirtyForEdit = useMemo(() => editModalParentId !== selectedDistrict?.parentId, [editModalParentId, selectedDistrict]);
  const storeSelectOptions = useMemo(() => {
    const filteredStores = isOnlyOrphanStores
      ? regionStores.filter((store) => isNone(store.district))
      : regionStores;
    return filteredStores.map((store) => ({ label: getStoreDisplayName(store), value: store.id }));
  }, [regionStores, isOnlyOrphanStores]);
  const storeSelectValue = useMemo(() => match()(
    [() => regionStores.length === 0, () => ({ label: 'Loading...', value: '' })],
    [() => isSome(addModalStore), () => ({ label: getStoreDisplayName(addModalStore), value: addModalStore.id })],
    [Default, () => ({ label: 'Select a store', value: '' })],
  ), [addModalStore, regionStores]);

  const prevSelectedRegionRef = useRef();

  // function to return latest district for the store

   function findInScheduledStoreDistrict(store) {
    if (scheduleList && scheduleList.length > 0) {
      const storeInSchedule = scheduleList.find((schedule) => schedule.payload.id === store.id);
      if (storeInSchedule) {
        return storeInSchedule.destinationLocation;
      }
    }
    return null;
  }

  // Update editModalParentId when selectedDistrict changes
  useEffect(() => {
    prevSelectedRegionRef.current = selectedRegion;
  });

  // Get the scheduleList and check any districtStores is in the scheduleList
  // If presnet override the districtStores with the scheduleList
  useEffect(() => {
    if (districtStores && scheduleList && districtStores !== null && scheduleList !== null) {
      districtStores.forEach((store) => {
        const storeInSchedule = scheduleList.find((schedule) => schedule.payload.id === store.id);
        if (storeInSchedule) {
          store.district = storeInSchedule.payload.district;
        }
      });
    }
  }, [districtStores, scheduleList, scheduleEditList]);

  // handle continue edit flow
  const handleContinueEdit = () => {
    setContinueEdit(true);
  };

  useEffect(() => {
    if (!isScheduling) {
      setContinueEdit(true);
    }
  }, [isScheduling]);

  useEffect(() => {
    if (districtStores && scheduleEditList && selectedDistrict && districtStores !== null && scheduleEditList !== null && selectedDistrict !== null) {
      // find the store in the scheduleEditList and update the districtStores
      districtStores.forEach((store) => {
        // schedule.payload.districtId should not be equal to the selectedDistrict.id
        const storeInSchedule = scheduleEditList.find((schedule) => schedule.payload.id === store.id && schedule.payload.districtId !== selectedDistrict.id);
        // remove store from the districtStores
        if (storeInSchedule) {
          const index = districtStores.findIndex(() => store.id === storeInSchedule.payload.id);
          if (index !== -1) {
            districtStores.splice(index, 1);
          }
        }
      });
      // Iterate thru the scheduleEditList and add the store to the districtStores
      scheduleEditList.forEach((schedule) => {
         if (schedule.payload.districtId === selectedDistrict.id) {
          districtStores.push(schedule.payload);
        }
      });
      setCuratedDistrictStores(districtStores);
    } else {
      setCuratedDistrictStores(districtStores);
    }
  }, [districtStores, scheduleEditList, selectedDistrict]);

  // if selectedTerritory gets chenged then set the districtStores to null
  useEffect(() => {
    if (activeNodeId !== null) {
      const activeNode = hierarchyNodes.find((node) => node.id === activeNodeId);
     if (activeNode && (activeNode.nodeType === 'TERRITORY' || activeNode.nodeType === 'REGION')) {
      setCuratedDistrictStores(null);
    }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeNodeId]);

  // Reset editModalParentId and editModalGrandparentId when selectedDistrict or selectedStore changes
  useEffect(() => {
    setEditModalGrandparentId(selectedDistrict?.parentId ?? '');
    setEditModalParentId(selectedDistrict?.id ?? '');
  }, [selectedDistrict, selectedStore]);

  // Reset addModalStore when isAddModalOpen changes
  useEffect(() => {
    setAddModalStore(null);
  }, [isAddModalOpen]);

  // Reset regionStores when selectedRegion changes
  useEffect(() => {
    if (selectedRegion !== prevSelectedRegionRef.current) {
      setRegionStores([]);
    }
  }, [selectedRegion, prevSelectedRegionRef]);

  // Update regionStores when selectedRegion changes
  useEffect(() => {
    if (isAddModalOpen && regionStores.length === 0) {
      // eslint-disable-next-line promise/catch-or-return
      getRegionStores(selectedRegion, accessToken)
        .then(setRegionStores)
        .catch(setError);
    }
  }, [isAddModalOpen, regionStores, selectedRegion, setError, setIsPageLoading, isOnlyOrphanStores, accessToken]);

  // When isOnlyOrphanStores changes, reset addModalStore
  useEffect(() => {
    if (!isOnlyOrphanStores) {
      setAddModalStore(null);
    }
  }, [isOnlyOrphanStores]);

  const actionComponent = useMemo(() => (
    isSome(selectedDistrict)
    && userHasWritePermissionForRegion(userGroups, hierarchyNodeLookupTable, selectedRegion)
    && (<AddNodeButton label="Add Store" onClick={() => setIsAddModalOpen(true)} />)
  ), [selectedDistrict, userGroups, hierarchyNodeLookupTable, selectedRegion]);

  const createScheduleChangeModel = (individualSelectedStore, newDistrict, currentOperation) => ({
      brand: selectedBrand.name,
      createdBy: scheduleEditList && scheduleEditList.length > 0 ? scheduleEditList[0].createdBy : user,
      destinationLocation: newDistrict.name,
      entityId: individualSelectedStore.id,
      entityType: 'Store',
      eventName: '',
      existingLocation: individualSelectedStore.district,
      groupId: '',
      modifiedBy: user,
      operation: currentOperation,
      payload: {
        district: individualSelectedStore.district,
        districtId: newDistrict.id,
        id: individualSelectedStore.id,
        name: individualSelectedStore.name,
        region: individualSelectedStore.region,
        storeNumber: individualSelectedStore.storeNumber
      },
      region: selectedRegion.name,
      scheduledDate: '',
      // find in the scheduleEditList for entityId if presnet get the scheduleId
      scheduleId: scheduleEditList !== null ? scheduleList.find((schedule) => schedule.payload.id === individualSelectedStore.id)?.scheduleId : null,
      scheduleStatus: ''
    });

  return (
    <>
      {isEditModalOpen && (
        <Modal
          footerSlot={(isScheduling && findInScheduledStoreDistrict(selectedStore) !== null && continueEdit) || (isScheduling && findInScheduledStoreDistrict(selectedStore) == null && !continueEdit) ? (
            <ButtonGroup className="eds-flex eds-flex--direction-row eds-flex--justify-content-space-between">
              <Button
                afterSlot={<Check />}
                disabled={!(isDirtyForEdit)}
                size="small"
                variant="primary"
                onClick={() => {
                  // capture if any changes to district-select if selected get new seleted data for Select
                  const newDistrict = isSome(editModalParentId)
                  ? hierarchyNodeLookupTable?.[editModalParentId]
                  : selectedDistrict;
                  // add the store to the currentSheduleChangesList
                  const currentSheduleChangesListCopy = Array.isArray(currentSheduleChangesList) ? [...currentSheduleChangesList] : [];
                  const storeIndex = currentSheduleChangesListCopy.findIndex((store) => store.entityId === selectedStore.id);
                  const scheduleChangeModel = createScheduleChangeModel(selectedStore, newDistrict === undefined ? null : newDistrict, 'MIGRATION');
                  if (storeIndex === -1) {
                    currentSheduleChangesListCopy.push(scheduleChangeModel);
                  } else {
                    currentSheduleChangesListCopy[storeIndex] = scheduleChangeModel;
                  }
                  setCurrentSheduleChangesList(currentSheduleChangesListCopy);
                  setIsEditModalOpen(false);
                }}
              >Apply for Scheduling
              </Button>
              <Button afterSlot={<Close />} size="small" variant="secondary" onClick={() => setIsEditModalOpen(false)}>Close</Button>
            </ButtonGroup>
)
            : continueEdit && (
              <ButtonGroup className="eds-flex eds-flex--direction-row eds-flex--justify-content-space-between">
                <Button
                  afterSlot={<Check />}
                  disabled={!(isDirtyForEdit)}
                  size="small"
                  variant="primary"
                  onClick={() => {
                  const isNotWhitespaceOrNilParentId = isNotWhitespaceOrNil(editModalParentId);
                  const editConfirmed = isNotWhitespaceOrNilParentId
                    ? true
                    // eslint-disable-next-line no-alert
                    : window.confirm(`Remove district from store ${getStoreDisplayName(selectedStore)}?`);
                  if (editConfirmed) {
                    setIsPageLoading(true);
                    const newDistrictId = isNotWhitespaceOrNilParentId
                      ? editModalParentId
                      : undefined;
                    const updatedStore = { ...selectedStore, districtId: newDistrictId };
                    // eslint-disable-next-line promise/catch-or-return
                    updateStore(accessToken, updatedStore)
                      .then((savedStore) => {
                        const newDistrict = isSome(newDistrictId)
                          ? hierarchyNodeLookupTable?.[newDistrictId]
                          : selectedDistrict;
                        const newTerritory = hierarchyNodeLookupTable?.[newDistrict?.parentId];
                        setSelectedTerritory(newTerritory);
                        setSelectedDistrict(null); // Set to null to force a refresh
                        setSelectedDistrict(newDistrict);
                        setSelectedStore(savedStore);
                        setSuccessMessage(`Store ${getStoreDisplayName(updatedStore)} updated.`);
                        return savedStore;
                      })
                      .catch(setError)
                      .finally(() => setIsPageLoading(false));
                  }
                }}
                >Save
                </Button>
                <Button afterSlot={<Close />} size="small" variant="secondary" onClick={() => setIsEditModalOpen(false)}>Close</Button>
              </ButtonGroup>
          )}
          headerSlot="Edit Store"
          isOpen={isEditModalOpen}
          onDismiss={() => setIsEditModalOpen(false)}
        >
          {findInScheduledStoreDistrict(selectedStore) !== null && !continueEdit && (
          <>
            <p style={{ color: 'orange' }}>Warning! There is already a scheduled event for this store! <br />
              The district value will be updated to {findInScheduledStoreDistrict(selectedStore)}
            </p>
            <br />
            <p>Do you want to continue editing ?</p>
            <br />
            <Button size="small" variant="secondary" onClick={handleContinueEdit}> Continue </Button>
          </>
            )}
          {((findInScheduledStoreDistrict(selectedStore) !== null && continueEdit) || (findInScheduledStoreDistrict(selectedStore) == null && !continueEdit) || (!isScheduling)) && (
          <TextGroup>
            <TextField
              disabled
              label="Store"
              value={getStoreDisplayName(selectedStore)}
            />
            <Select
              id="territory-select"
              label="Territory"
              options={grandparentSelectOptions}
              value={grandparentSelectValue}
              onChange={(e) => setEditModalGrandparentId(e?.value)}
            />
            <Select
              id="district-select"
              label="District"
              options={parentSelectOptions}
               // if districtStores conatins the selectedStore then get the district from the districtStores
              value={parentSelectValue}
              onChange={(e) => setEditModalParentId(e?.value)}
            />
          </TextGroup>

          )}

        </Modal>
      )}
      {isAddModalOpen && (
        <Modal
          footerSlot={isScheduling ? (
            <ButtonGroup className="eds-flex eds-flex--direction-row eds-flex--justify-content-space-between">
              <Button
                afterSlot={<Check />}
                disabled={isNone(addModalStore)}
                size="small"
                variant="primary"
                onClick={() => {
                  const updatedStore = { ...addModalStore, districtId: selectedDistrict?.id };
                  const currentSheduleChangesListCopy = Array.isArray(currentSheduleChangesList) ? [...currentSheduleChangesList] : [];
                  const storeIndex = currentSheduleChangesListCopy.findIndex((store) => store.entityId === updatedStore.id);
                  const scheduleChangeModel = createScheduleChangeModel(updatedStore, selectedDistrict, 'CREATION');
                  if (storeIndex === -1) {
                    currentSheduleChangesListCopy.push(scheduleChangeModel);
                  } else {
                    currentSheduleChangesListCopy[storeIndex] = scheduleChangeModel;
                  }
                  setCurrentSheduleChangesList(currentSheduleChangesListCopy);
                  setIsAddModalOpen(false);
                }}
              >Apply for Scheduling
              </Button>
              <Button afterSlot={<Close />} size="small" variant="secondary" onClick={() => setIsEditModalOpen(false)}>Close</Button>
            </ButtonGroup>
)
          : (
            <ButtonGroup className="eds-flex eds-flex--direction-row eds-flex--justify-content-space-between">
              <Button
                afterSlot={<Check />}
                disabled={isNone(addModalStore)}
                size="small"
                variant="primary"
                onClick={() => {
                  setIsPageLoading(true);
                  const updatedStore = { ...addModalStore, districtId: selectedDistrict?.id };
                  // eslint-disable-next-line promise/catch-or-return
                  updateStore(accessToken, updatedStore)
                    .then((savedStore) => {
                      setSelectedStore(savedStore);
                      setSelectedDistrict(null); // Set to null to force a refresh
                      setSelectedDistrict(selectedDistrict);
                      setSuccessMessage(`Store ${getStoreDisplayName(savedStore)} added.`);
                      return savedStore;
                    })
                    .catch(setError)
                    .finally(() => setIsPageLoading(false));
                }}
              >Add
              </Button>
              <Button afterSlot={<Close />} size="small" variant="secondary" onClick={() => setIsAddModalOpen(false)}>Close</Button>
            </ButtonGroup>
          )}
          headerSlot="Add Store"
          isOpen={isAddModalOpen}
          onDismiss={() => setIsAddModalOpen(false)}
        >
          <TextGroup>
            <Toggle
              checked={isOnlyOrphanStores}
              disabled={regionStores.length === 0}
              id="only-orphan-stores"
              label="Only Orphans"
              onChange={() => setIsOnlyOrphanStores(!isOnlyOrphanStores)}
            />
            <Select
              id="stores-add-modal-district-select"
              label="Store"
              options={storeSelectOptions}
              value={storeSelectValue}
              onChange={(e) => setAddModalStore(regionStores.find((store) => store.id === e?.value))}
            />
            <TextField
              disabled
              label="Territory"
              value={selectedTerritory?.name}
            />
            <TextField
              disabled
              label="District"
              value={selectedDistrict?.name}
            />
          </TextGroup>
        </Modal>
      )}
      <HierarchyColumnTitle actionComponent={actionComponent} title="Stores" />
      {
        curatedDistrictStores && curatedDistrictStores.map((store) => (
          <HierarchyNode
            key={store.id}
            getHierarchyCopyPasteValue={getStoreDisplayName}
            getHierarchyName={getStoreDisplayName}
            hierarchyNode={store}
            isDeletable={false}
            isEditable={userHasWritePermissionForRegion(userGroups, hierarchyNodeLookupTable, selectedRegion)}
            isSelected={store.id === selectedStore?.id}
            onClick={() => setSelectedStore(store)}
            onEdit={() => setIsEditModalOpen(true)}
          />
        ))
      }
    </>
  );
};

Stores.propTypes = {
  isScheduling: PropTypes.bool.isRequired,
  scheduleEditList: PropTypes.arrayOf(
    PropTypes.shape({
      brand: PropTypes.string.isRequired,
      createdAt: PropTypes.string,
      createdBy: PropTypes.string.isRequired,
      eventName: PropTypes.string.isRequired,
      groupId: PropTypes.string.isRequired,
      payload: PropTypes.shape({}).isRequired,
      region: PropTypes.string.isRequired,
      scheduledDate: PropTypes.string.isRequired,
      scheduleId: PropTypes.string.isRequired,
      scheduleStatus: PropTypes.string.isRequired
    })
  ).isRequired,
  scheduleList: PropTypes.arrayOf(
    PropTypes.shape({
      brand: PropTypes.string.isRequired,
      createdAt: PropTypes.string,
      createdBy: PropTypes.string.isRequired,
      eventName: PropTypes.string.isRequired,
      groupId: PropTypes.string.isRequired,
      payload: PropTypes.shape({}).isRequired,
      region: PropTypes.string.isRequired,
      scheduledDate: PropTypes.string.isRequired,
      scheduleId: PropTypes.string.isRequired,
      scheduleStatus: PropTypes.string.isRequired
    })
  ).isRequired,
};

export default Stores;
