import {
 Button, ButtonGroup, TextField, TextGroup
} from '@nike/eds';
import {
 Check, Close, Delete
} from '@nike/nike-design-system-icons';
import { isSome } from '@nike/rcf-fp';
import { find } from 'lodash';
import PropTypes from 'prop-types';
import React, {
 useContext, useEffect, useMemo, useState
} from 'react';
import { v4 as uuidv4 } from 'uuid';

import { deleteNode, updateNode, createNode } from '../../services/hierarchy-service';
import { isChildOf } from '../../utils/hierarchy-utils';
import { userCanDeleteDistrict, userCanEditDistrict, userHasWritePermissionForRegion } from '../../utils/permissions';
import { isNotWhitespaceOrNil } from '../../utils/string-utils';
import { Modal, Select } from '../eds-custom';
import { StoreHierarchyContext } from '../StoreHierarchyContextProvider';

import AddNodeButton from './common/AddNodeButton';
import HierarchyColumnTitle from './common/HierarchyColumnTitle';
import HierarchyNode from './common/HierarchyNode';

const Districts = ({ isScheduling, scheduleList, scheduleEditList }) => {
  const {
    accessToken,
    currentSheduleChangesList,
    selectedTerritory = {},
    hierarchyNodes = [],
    selectedBrand,
    selectedDistrict,
    userGroups,
    hierarchyNodeLookupTable,
    districtStores,
    selectedRegion,
    setCurrentSheduleChangesList,
    setIsPageLoading,
    setHierarchyNodes,
    setEditList,
    setError,
    setSuccessMessage,
    setActiveNodeId,
    user
  } = useContext(StoreHierarchyContext);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editModalName, setEditModalName] = useState(selectedDistrict?.name ?? '');
  const [editModalParentId, setEditModalParentId] = useState(selectedDistrict?.parentId ?? '');
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [addModalName, setAddModalName] = useState('');
  const [continueEdit, setContinueEdit] = useState(false);
  const [curatedDistricts, setcuratedDistricts] = useState([]);

  // Update editModalName and newParentRegion when selectedTerritory changes
  useEffect(() => {
    setEditModalName(selectedDistrict?.name ?? '');
    setEditModalParentId(selectedDistrict?.parentId ?? '');
  }, [selectedDistrict, setEditModalName, setEditModalParentId]);

  // Reset addModalName and addModalParentId when isAddModalOpen changes
  useEffect(() => {
      setAddModalName('');
  }, [isAddModalOpen]);

  // function to return latest district for the store

  function findInScheduledDistrict(district) {
    if (scheduleList && scheduleList.length > 0) {
      const storeInSchedule = scheduleList.find((schedule) => schedule.payload.id === district.id);
      if (storeInSchedule) {
        return storeInSchedule.destinationLocation;
      }
    }
    return null;
  }

  const territories = useMemo(() => hierarchyNodes.filter(isChildOf(selectedRegion)), [hierarchyNodes, selectedRegion]);
  const districts = useMemo(() => hierarchyNodes.filter(isChildOf(selectedTerritory)), [hierarchyNodes, selectedTerritory]);
  const parentSelectValue = useMemo((newParent = hierarchyNodeLookupTable?.[editModalParentId] ?? {}) => ({ label: newParent?.name, value: newParent?.id }), [hierarchyNodeLookupTable, editModalParentId]);
  const parentSelectOptions = useMemo(() => territories.map(({ id, name }) => ({ label: name, value: id })), [territories]);
  const isDirtyEditModalName = useMemo(() => editModalName !== selectedDistrict?.name, [editModalName, selectedDistrict]);
  const isDirtyEditModalParentId = useMemo(() => editModalParentId !== selectedDistrict?.parentId, [editModalParentId, selectedDistrict]);
  const isDirtyForEdit = useMemo(() => isDirtyEditModalName || isDirtyEditModalParentId, [isDirtyEditModalName, isDirtyEditModalParentId]);
  const isDuplicateNameForEdit = useMemo(() => hierarchyNodes.some((node) => node.name === editModalName
      && node.nodeType === 'DISTRICT'
      && node.parentId === editModalParentId
      && node.id !== selectedDistrict?.id),
    [hierarchyNodes, editModalName, editModalParentId, selectedDistrict]);
  const isDuplicateNameForScheduleEdit = useMemo(() => scheduleEditList && scheduleEditList.some((node) => node.payload.name === editModalName
    && node.payload.nodeType === 'DISTRICT'
    && node.payload.parentId === editModalParentId
    && node.payload.id !== selectedDistrict?.id),
  [scheduleEditList, editModalName, editModalParentId, selectedDistrict]);
  const isValidNameForEdit = useMemo(() => !isDuplicateNameForEdit && !isDuplicateNameForScheduleEdit && isNotWhitespaceOrNil(editModalName), [isDuplicateNameForEdit, isDuplicateNameForScheduleEdit, editModalName]);
  const isValidForEdit = useMemo(() => isValidNameForEdit && isNotWhitespaceOrNil(editModalParentId), [editModalParentId, isValidNameForEdit]);
  const DUPLICATE_NAME_ERROR_MESSAGE = 'A district with this name already exists.';
  const editModalNameErrorMessage = useMemo(() => ((isDuplicateNameForEdit || isDuplicateNameForScheduleEdit) ? 'A district with this name already exists.' : ''), [isDuplicateNameForEdit, isDuplicateNameForScheduleEdit]);
  const isDuplicateNameForAdd = useMemo(() => hierarchyNodes.some((node) => node.name === addModalName
      && node.nodeType === 'DISTRICT'
      && node.parentId === selectedTerritory?.id),
  [hierarchyNodes, addModalName, selectedTerritory]);
  const isDuplicateNameForScheduleAdd = useMemo(() => scheduleEditList && scheduleEditList.some((node) => node.payload.name === addModalName
    && node.payload.nodeType === 'DISTRICT'
    && node.payload.parentId === selectedTerritory?.id),
  [scheduleEditList, addModalName, selectedTerritory]);
  const isValidForAdd = useMemo(() => isNotWhitespaceOrNil(addModalName) && !isDuplicateNameForAdd && !isDuplicateNameForScheduleAdd, [addModalName, isDuplicateNameForAdd, isDuplicateNameForScheduleAdd]);
  const addModalErrorMessage = useMemo(() => ((isDuplicateNameForAdd || isDuplicateNameForScheduleAdd) ? DUPLICATE_NAME_ERROR_MESSAGE : ''), [isDuplicateNameForAdd, isDuplicateNameForScheduleAdd]);
  const actionComponent = useMemo(() => (
    isSome(selectedTerritory)
      && userHasWritePermissionForRegion(userGroups, hierarchyNodeLookupTable, selectedRegion)
      && (<AddNodeButton label="Add District" onClick={() => setIsAddModalOpen(true)} />)
  ), [selectedTerritory, userGroups, hierarchyNodeLookupTable, selectedRegion]);

  const createScheduleChangeModel = (individualSelectedDistrict, selectectedterritoryName, currentOperation, uuId) => ({
    brand: selectedBrand.name,
    createdBy: scheduleEditList && scheduleEditList.length > 0 ? scheduleEditList[0].createdBy : user,
    destinationLocation: currentOperation !== 'DELETION' ? find(hierarchyNodes, { id: individualSelectedDistrict.parentId }).name : '',
    entityId: individualSelectedDistrict.id === undefined ? uuId : individualSelectedDistrict.id,
    entityType: 'District',
    eventName: '',
    existingLocation: selectectedterritoryName,
    groupId: '',
    modifiedBy: user,
    operation: currentOperation,
    payload: {
      id: individualSelectedDistrict.id === undefined ? uuId : individualSelectedDistrict.id,
      name: individualSelectedDistrict.name,
      nodeType: individualSelectedDistrict.nodeType,
      parentId: individualSelectedDistrict.parentId
    },
    region: selectedRegion.name,
    scheduledDate: '',
    scheduleId: null,
    scheduleStatus: ''
  });

    useEffect(() => {
      if (!isScheduling) {
        setContinueEdit(true);
      }
    }, [isScheduling]);

  useEffect(() => {
    const updatedDistricts = [...districts];
    if (updatedDistricts && scheduleEditList && selectedTerritory && updatedDistricts !== null && scheduleEditList !== null && selectedTerritory !== null) {
      // find the district in the scheduleEditList and update the districts
      updatedDistricts.forEach((district) => {
        // schedule.payload.id should not be equal to the selectedTerritory.id
        const districtInSchedule = scheduleEditList.find((schedule) => schedule.payload.id === district.id && schedule.payload.parentId !== selectedTerritory.id);
        // remove store from the districtStores
        if (districtInSchedule) {
          const index = updatedDistricts.findIndex((d) => d.id === districtInSchedule.payload.id);
          if (index !== -1) {
            updatedDistricts.splice(index, 1);
          }
        }
      });
      scheduleEditList.forEach((schedule) => {
         if (schedule.payload.parentId === selectedTerritory.id && schedule.operation !== 'DELETION') {
          schedule.payload.parentId = selectedTerritory.id;
          const index = updatedDistricts.findIndex((d) => d.id === schedule.payload.id);
          if (index !== -1) {
            updatedDistricts[index] = schedule.payload;
          } else {
            updatedDistricts.push(schedule.payload);
          }
        }
      });
      setcuratedDistricts(updatedDistricts);
    } else {
      setcuratedDistricts(districts);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [districts, scheduleEditList, selectedTerritory]);

  // handle continue edit flow
  const handleContinueEdit = () => {
    setContinueEdit(true);
  };

  // Set EditList to scheduleEditList so that in contextHierarchyProvider we can decide the effective territory and region based on the scheduledEditList
 const handleOnDistrictClick = (district) => {
    if (scheduleEditList && scheduleEditList.length > 0) {
        setEditList(scheduleEditList);
    }
    setActiveNodeId(district.id);
  };

  return (
    <>
      {isEditModalOpen && (
        <Modal
          footerSlot={(isScheduling && findInScheduledDistrict(selectedDistrict) !== null && continueEdit) || (isScheduling && findInScheduledDistrict(selectedDistrict) == null && !continueEdit) ? (
            <ButtonGroup className="eds-flex eds-flex--direction-row eds-flex--justify-content-space-between">
              <Button
                afterSlot={<Check />}
                disabled={!(isDirtyForEdit && isValidForEdit)}
                size="small"
                variant="primary"
                onClick={() => {
                // capture if any changes to district-select if selected get new seleted data for Select
                const updatedDistrict = { ...selectedDistrict, name: editModalName, parentId: editModalParentId };
                // add the district change to the currentSheduleChangesList
                const currentSheduleChangesListCopy = Array.isArray(currentSheduleChangesList) ? [...currentSheduleChangesList] : [];
                const storeIndex = currentSheduleChangesListCopy.findIndex((district) => district.entityId === updatedDistrict.id);
                const scheduleChangeModel = createScheduleChangeModel(updatedDistrict, selectedTerritory.name, 'MIGRATION', null);
                if (storeIndex === -1) {
                  currentSheduleChangesListCopy.push(scheduleChangeModel);
                } else {
                  currentSheduleChangesListCopy[storeIndex] = scheduleChangeModel;
                }
                setCurrentSheduleChangesList(currentSheduleChangesListCopy);
                setIsEditModalOpen(false);
              }}
              >Apply for Scheduling
              </Button>
              <Button
                afterSlot={<Delete />}
                disabled={!userCanDeleteDistrict(userGroups, hierarchyNodeLookupTable, districtStores, selectedDistrict)}
                size="small"
                variant="secondary"
                onClick={() => {
                  // eslint-disable-next-line no-alert
                  const confirmDelete = window.confirm(`Delete district ${selectedDistrict?.name}?`);
                  if (confirmDelete) {
                    const currentSheduleChangesListCopy = Array.isArray(currentSheduleChangesList) ? [...currentSheduleChangesList] : [];
                    const storeIndex = currentSheduleChangesListCopy.findIndex((district) => district.entityId === selectedDistrict.id);
                    const scheduleChangeModel = createScheduleChangeModel(selectedDistrict, selectedTerritory.name, 'DELETION', null);
                    if (storeIndex === -1) {
                      currentSheduleChangesListCopy.push(scheduleChangeModel);
                    }
                    setCurrentSheduleChangesList(currentSheduleChangesListCopy);
                    setIsEditModalOpen(false);
                  }
              }}
              >Delete
              </Button>
              <Button afterSlot={<Close />} size="small" variant="secondary" onClick={() => setIsEditModalOpen(false)}>Close</Button>
            </ButtonGroup>
          )
        : continueEdit && (
          <ButtonGroup className="eds-flex eds-flex--direction-row eds-flex--justify-content-space-between">
            <Button
              afterSlot={<Check />}
              disabled={!(isDirtyForEdit && isValidForEdit)}
              size="small"
              variant="primary"
              onClick={() => {
              setIsPageLoading(true);
              const updatedDistrict = { ...selectedDistrict, name: editModalName, parentId: editModalParentId };
              // eslint-disable-next-line promise/catch-or-return
                updateNode(updatedDistrict, accessToken)
                .then((nodes) => {
                  setHierarchyNodes(nodes);
                  setActiveNodeId(updatedDistrict.id);
                  setSuccessMessage(`District ${updatedDistrict.name} updated.`);
                  return updatedDistrict;
                })
                .catch(setError)
                .finally(() => setIsPageLoading(false));
          }}
            >Save
            </Button>
            <Button
              afterSlot={<Delete />}
              disabled={!userCanDeleteDistrict(userGroups, hierarchyNodeLookupTable, districtStores, selectedDistrict)}
              size="small"
              variant="secondary"
              onClick={() => {
              // eslint-disable-next-line no-alert
              const confirmDelete = window.confirm(`Delete district ${selectedDistrict?.name}?`);
              if (confirmDelete) {
                setIsPageLoading(true);
                // eslint-disable-next-line promise/catch-or-return
                deleteNode(selectedDistrict, accessToken)
                  .then((nodes) => {
                    setHierarchyNodes(nodes);
                    setActiveNodeId(selectedDistrict.parentId);
                    setSuccessMessage(`District ${selectedDistrict?.name} deleted.`);
                    return selectedDistrict;
                  })
                  .catch(setError)
                  .finally(() => setIsPageLoading(false));
              }
          }}
            >Delete
            </Button>
            <Button afterSlot={<Close />} size="small" variant="secondary" onClick={() => setIsEditModalOpen(false)}>Close</Button>
          </ButtonGroup>
)}
          headerSlot="Edit District"
          isOpen={isEditModalOpen}
          onDismiss={() => setIsEditModalOpen(false)}
        >
          {findInScheduledDistrict(selectedDistrict) !== null && !continueEdit && (
          <>
            <p style={{ color: 'orange' }}>Warning! There is already a scheduled event for this district! <br />
              {findInScheduledDistrict(selectedDistrict) ? `The district is scheduled to move to ${findInScheduledDistrict(selectedDistrict)}.` : 'This district is scheduled to be deleted.'}
            </p>
            <br />
            <p>Do you want to continue editing ?</p>
            <br />
            <Button size="small" variant="secondary" onClick={handleContinueEdit}> Continue </Button>
          </>
            )}
            {((findInScheduledDistrict(selectedDistrict) !== null && continueEdit) || (findInScheduledDistrict(selectedDistrict) == null && !continueEdit) || (!isScheduling)) && (
            <TextGroup>
              <TextField
                errorMessage={editModalNameErrorMessage}
                hasErrors={isDuplicateNameForEdit || isDuplicateNameForScheduleEdit}
                label="Name"
                value={editModalName}
                onChange={(e) => setEditModalName(e.target.value)}
              />
              <Select
                id="territory-select"
                label="Territory"
                options={parentSelectOptions}
                value={parentSelectValue}
                onChange={(e) => setEditModalParentId(e?.value)}
              />
            </TextGroup>
          )}
        </Modal>
      )}
      {isAddModalOpen && (
        <Modal
          footerSlot={isScheduling ? (
            <ButtonGroup className="eds-flex eds-flex--direction-row eds-flex--justify-content-space-between">
              <Button
                afterSlot={<Check />}
                disabled={!isValidForAdd}
                size="small"
                variant="primary"
                onClick={() => {
                  const newDistrict = { name: addModalName, nodeType: 'DISTRICT', parentId: selectedTerritory?.id };
                  const currentSheduleChangesListCopy = Array.isArray(currentSheduleChangesList) ? [...currentSheduleChangesList] : [];
                  const storeIndex = currentSheduleChangesListCopy.findIndex((district) => district.entityId === newDistrict.id);
                  const scheduleChangeModel = createScheduleChangeModel(newDistrict, null, 'CREATION', uuidv4());
                  if (storeIndex === -1) {
                    currentSheduleChangesListCopy.push(scheduleChangeModel);
                  } else {
                    currentSheduleChangesListCopy[storeIndex] = scheduleChangeModel;
                  }
                  setCurrentSheduleChangesList(currentSheduleChangesListCopy);
                  setIsAddModalOpen(false);
                }}
              >Apply for Scheduling
              </Button>
              <Button afterSlot={<Close />} size="small" variant="secondary" onClick={() => setIsAddModalOpen(false)}>Close</Button>
            </ButtonGroup>
          )
        : (
          <ButtonGroup className="eds-flex eds-flex--direction-row eds-flex--justify-content-space-between">
            <Button
              afterSlot={<Check />}
              disabled={!isValidForAdd}
              size="small"
              variant="primary"
              onClick={() => {
              setIsPageLoading(true);
              const newDistrict = { name: addModalName, nodeType: 'DISTRICT', parentId: selectedTerritory?.id };
              // eslint-disable-next-line promise/catch-or-return
              createNode(newDistrict, accessToken)
                .then((nodes) => {
                  setHierarchyNodes(nodes);
                  const savedNewDistrict = nodes.find((node) => node.name === newDistrict.name
                    && node.parentId === newDistrict.parentId
                    && node.nodeType === 'DISTRICT');
                  setActiveNodeId(savedNewDistrict.id);
                  setSuccessMessage(`District ${newDistrict.name} added.`);
                  return newDistrict;
                })
                .catch(setError)
                .finally(() => {
                  setIsAddModalOpen(false);
                  setIsPageLoading(false);
                });
            }}
            >Add
            </Button>
            <Button afterSlot={<Close />} size="small" variant="secondary" onClick={() => setIsAddModalOpen(false)}>Close</Button>
          </ButtonGroup>
)}
          headerSlot="Add District"
          isOpen={isAddModalOpen}
          onDismiss={() => setIsAddModalOpen(false)}
        >
          <TextGroup>
            <TextField
              errorMessage={addModalErrorMessage}
              hasErrors={isDuplicateNameForAdd || isDuplicateNameForScheduleAdd}
              label="Name"
              value={addModalName}
              onChange={(e) => setAddModalName(e.target.value)}
            />
            <TextField
              disabled
              label="Territory"
              value={selectedTerritory?.name}
            />
          </TextGroup>
        </Modal>
      )}
      <HierarchyColumnTitle actionComponent={actionComponent} title="Districts" />
      {
      curatedDistricts && curatedDistricts.map((district) => (
        <HierarchyNode
          key={district.id}
          hierarchyNode={district}
          isDeletable={userCanDeleteDistrict(userGroups, hierarchyNodeLookupTable, districtStores, district)}
          isEditable={userCanEditDistrict(userGroups, hierarchyNodeLookupTable, district)}
          isSelected={district.id === selectedDistrict?.id}
          onClick={() => {
            handleOnDistrictClick(district);
          }}
          onEdit={() => setIsEditModalOpen(true)}
        />
      ))
     }
    </>
  );
};

Districts.propTypes = {
  isScheduling: PropTypes.bool.isRequired,
  scheduleEditList: PropTypes.arrayOf(
    PropTypes.shape({
      brand: PropTypes.string.isRequired,
      createdAt: PropTypes.string.isRequired,
      createdBy: PropTypes.string.isRequired,
      eventName: PropTypes.string.isRequired,
      groupId: PropTypes.string.isRequired,
      payload: PropTypes.shape({}).isRequired,
      region: PropTypes.string.isRequired,
      scheduledDate: PropTypes.string.isRequired,
      scheduleId: PropTypes.string.isRequired,
      scheduleStatus: PropTypes.string.isRequired
    })
  ).isRequired,
  scheduleList: PropTypes.arrayOf(
    PropTypes.shape({
      brand: PropTypes.string.isRequired,
      createdAt: PropTypes.string.isRequired,
      createdBy: PropTypes.string.isRequired,
      eventName: PropTypes.string.isRequired,
      groupId: PropTypes.string.isRequired,
      payload: PropTypes.shape({}).isRequired,
      region: PropTypes.string.isRequired,
      scheduledDate: PropTypes.string.isRequired,
      scheduleId: PropTypes.string.isRequired,
      scheduleStatus: PropTypes.string.isRequired
    })
  ).isRequired,
};
export default Districts;
