/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Modal, Select } from '@nike/eds';
import { Loading } from '@nike/frame-component-library';
import { Edit, Delete, Show } from '@nike/nike-design-system-icons';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { requestWrapperHierarchySchedules } from '../../../utils/service-calls/reusable';
import { Table } from '../../reusable';
import StoreHierarchyContextProvider from '../components/StoreHierarchyContextProvider';
import StoreHierarchyPage from '../components/StoreHierarchyPage';
import edsCss from '../styles/eds.css.js';
import hierarchyCss from '../styles/store-hierarchy.css.js';

const StoreHierarchyScheduleMain = () => {
  const [isScheduling, setIsScheduling] = useState(false);
  const auth = useSelector((state) => state?.authorizationReducer?.auth ?? '');
  const accessToken = useMemo(() => auth?.accessToken ?? '', [auth]);
  const [groupedScheduleList, setGroupedScheduleList] = useState([]);
  const [notCompletedSchedules, setNotCompletedSchedules] = useState([]);
  const [isSchedulesFetched, setIsSchedulesFetched] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [scheduleToDelete, setScheduleToDelete] = useState(null);
  const [isDeleteScheduleCompletedModalOpen, setIsDeleteScheduleCompletedModalOpen] = useState(false);
  const [scheduleEditList, setScheduleEditList] = useState([]);
  const [isDeletionInProgress, setIsDeletionInProgress] = useState(false);
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const [scheduleStatus, setScheduleStatus] = useState('DEFAULT');

  const scheduleStatusOptions = [
    { label: 'DEFAULT', value: 'DEFAULT' },
    { label: 'COMPLETED', value: 'COMPLETED' },
    { label: 'WORK_IN_PROGRESS', value: 'WORK_IN_PROGRESS' },
    { label: 'PENDING', value: 'PENDING' },
  ];

  const getScheduleList = async () => {
    const response = await requestWrapperHierarchySchedules(
      accessToken,
      '/store_hierarchy_schedule_ui/v1',
      'GET'
    );
    setNotCompletedSchedules(response.body.filter((schedule) => schedule.scheduleStatus !== 'COMPLETED'));
    // Group schedules by groupId, scheduleDate and createdBy and scheduleStatus
    const groupedSchedules = response.body.reduce((acc, schedule) => {
      const key = `${schedule.groupId}_${schedule.scheduledDate}_${schedule.scheduleStatus}`;
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(schedule);
      return acc;
    }, {});

    // take the first schedule from each group
    // take the following fields from the first schedule, groupId, region, brand, createdAt, createdBy, scheduledDate, scheduleStatus
    // add the grouped schedules to the groupedScheduleList
    const gsl = Object.values(groupedSchedules).map((group) => {
      const firstSchedule = group[0];
      return {
        brand: firstSchedule.brand,
        createdAt: firstSchedule.createdAt,
        createdBy: firstSchedule.createdBy,
        eventName: firstSchedule.eventName,
        groupId: firstSchedule.groupId,
        region: firstSchedule.region,
        scheduledDate: firstSchedule.scheduledDate,
        schedules: group,
        scheduleStatus: firstSchedule.scheduleStatus,
      };
    });
    setGroupedScheduleList(gsl);
    setIsSchedulesFetched(true);
  };

  const addSchedule = () => {
    setIsScheduling(true);
  };

  const viewSchedule = () => {
    setGroupedScheduleList([]);
    setNotCompletedSchedules([]);
    setScheduleEditList([]);
    setIsSchedulesFetched(false);
    setIsScheduling(false);
    setScheduleStatus('DEFAULT');
  };

  const deleteSchedules = async (schedulesToBeDeleted) => {
    if (schedulesToBeDeleted.original.scheduleStatus === 'COMPLETED') {
      return;
    }
    const { schedules } = schedulesToBeDeleted.original;
    const deletePromises = schedules.map((schedule) => requestWrapperHierarchySchedules(
      accessToken,
      `/store_hierarchy_schedule_ui/v1/${schedule.scheduleId}`,
      'DELETE'
    ));
    setIsDeletionInProgress(true);
    await Promise.all(deletePromises);
    setIsDeletionInProgress(false);
    setIsDeleteScheduleCompletedModalOpen(true);
  };

  const editSchedule = (schedulesToEdit) => {
    // Call the API to edit the schedule
    // Use the accessToken to call the API
    // Set the response to the schedulelist
    if (schedulesToEdit.original.scheduleStatus === 'COMPLETED') {
      return;
    }
    setScheduleEditList(schedulesToEdit.original.schedules);
    setIsScheduling(true);
  };

  const getColumns = () => {
    const columns = [
      {
        accessor: 'region',
        Header: 'Region',
      },
      {
        accessor: 'brand',
        Header: 'Brand',
      },
      {
        accessor: 'eventName',
        Header: 'EventName',
      },
      {
        accessor: 'createdAt',
        Header: 'Created Date',
      },
      {
        accessor: 'createdBy',
        Header: 'Created By',
      },
      {
        accessor: 'scheduledDate',
        Header: 'Scheduled Date',
      },
      {
        accessor: 'scheduleStatus',
        Header: 'Schedule Status',
      },
      {
        Cell: (d) => (
          // this button should be disabled if the schedule status is 'COMPLETED'
          // onClick should only be called if the schedule status is not 'COMPLETED'
          <p style={{ display: 'flex', justifyContent: 'center' }}>
            <Show
              size="m"
              title="View"
              onClick={() => { setScheduleToDelete(d); setIsViewModalOpen(true); }}
            />
          </p>
        ),
        Header: 'View',
        width: 80,
      },
      {
        Cell: (d) => (
          <p style={{ display: 'flex', justifyContent: 'center' }}>
            <Edit
              disabled={d.original.scheduleStatus === 'COMPLETED'}
              size="m"
              title="Edit"
              onClick={() => editSchedule(d)}
            />
          </p>
        ),
        Header: 'Edit',
        width: 80,
      },
      {
        Cell: (d) => (
          // this button should be disabled if the schedule status is 'COMPLETED'
          // onClick should only be called if the schedule status is not 'COMPLETED'
          <p style={{ display: 'flex', justifyContent: 'center' }}>
            <Delete
              disabled={d.original.scheduleStatus === 'COMPLETED'}
              size="m"
              title="Delete"
              onClick={() => { if (d.original.scheduleStatus !== 'COMPLETED') { setScheduleToDelete(d); setIsDeleteModalOpen(true); } }}
            />
          </p>
        ),
        Header: 'Delete',
        width: 80,
      },
    ];
    return columns;
  };

  useEffect(() => {
    getScheduleList();
  }, [accessToken, isScheduling]);

  useEffect(() => {
      const style = document.createElement('style');
      style.textContent = `${edsCss}\n${hierarchyCss}`;
      document.head.appendChild(style);
      return () => {
        document.head.removeChild(style);
      };
    }, []);

  return (
    <main className="ncss-col-sm-12 parent-edit-class">
      {!isSchedulesFetched && <Loading />}
      {isSchedulesFetched && isScheduling && (
        <>
          <br />
          <div className="ncss-row">
            <div className="ncss-col-sm-12">
              <div className="ncss-col-sm-3">
                <Button label="View Schedules" onClick={viewSchedule}>
                  View Schedules
                </Button>
              </div>
            </div>
          </div>
          <div className="ncss-row">
            <div className="ncss-col-sm-12">
              <StoreHierarchyContextProvider>
                <StoreHierarchyPage isScheduling isSchedulesFetched={isSchedulesFetched} scheduleEditList={scheduleEditList} scheduleList={notCompletedSchedules} />
              </StoreHierarchyContextProvider>
            </div>
          </div>
        </>
      )}
      {isSchedulesFetched && !isScheduling && (
        <>
          <div className="ncss-row">
            <div className="ncss-col-sm-12">
              <div className="ncss-col-sm-3">
                <Button label="Add Schedules" onClick={addSchedule}>
                  Add Schedules
                </Button>
              </div>
              <div className="ncss-col-sm-3" />
              <div className="ncss-col-sm-2" />
              <div className="ncss-col-sm-4">
                <div className="ncss-col-sm-6 ta-sm-r">
                  <label className="eds-type--subtitle-1" htmlFor="scheduleStatus">Schedule Status</label>
                </div>
                <div className="ncss-col-sm-6">
                  <Select
                    defaultValue={scheduleStatusOptions[0]}
                    id="scheduleStatus"
                    options={scheduleStatusOptions}
                    onChange={(value) => setScheduleStatus(value.value)}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="ncss-row">
            <div className="ncss-col-sm-12">
              <Table
                columns={getColumns()}
                data={groupedScheduleList.filter((schedule) => {
                  if (scheduleStatus === 'DEFAULT') {
                    return schedule.scheduleStatus !== 'COMPLETED';
                  }
                  return schedule.scheduleStatus === scheduleStatus;
                })}
                defaultPageSize={1}
                fetched={isSchedulesFetched}
              />
            </div>
          </div>
          { (isDeleteModalOpen || isViewModalOpen) && (
            <Modal
              footerSlot={isDeleteModalOpen && (
                <div className="footer-buttons">
                  <Button onClick={() => { deleteSchedules(scheduleToDelete); setIsDeleteModalOpen(false); setScheduleToDelete(null); }}>Delete</Button>
                </div>
                      )}
              headerSlot={isDeleteModalOpen ? 'Delete Schedules' : 'View Schedules'}
              isOpen={isDeleteModalOpen || isViewModalOpen}
              onDismiss={() => { setIsDeleteModalOpen(false); setScheduleToDelete(null); setIsViewModalOpen(false); }}
            >
              <table className="table">
                <thead>
                  <tr>
                    <th>Region</th>
                    <th>Brand</th>
                    <th>Entity Type</th>
                    <th>Entity Name</th>
                    <th>Existing Value</th>
                    <th>New Value</th>
                    <th>Operation</th>
                    <th>Modified By</th>
                  </tr>
                </thead>
                <tbody>
                  {scheduleToDelete && scheduleToDelete.original.schedules.map((schedule) => (
                    <tr key={schedule.scheduleId}>
                      <td>{schedule.region}</td>
                      <td>{schedule.brand}</td>
                      <td>{schedule.entityType}</td>
                      <td>{schedule.payload.name}</td>
                      <td>{schedule.existingLocation}</td>
                      <td>{schedule.destinationLocation}</td>
                      <td>{schedule.operation}</td>
                      <td>{schedule.modifiedBy}</td>
                    </tr>
                          ))}
                </tbody>
              </table>
            </Modal>
          )}
          {isDeletionInProgress && <Loading />}
          { isDeleteScheduleCompletedModalOpen && (
            <Modal
              footerSlot={(
                <div className="footer-buttons">
                  <Button onClick={() => {
                    setIsDeleteScheduleCompletedModalOpen(false);
                    setGroupedScheduleList([]);
                    setNotCompletedSchedules([]);
                    setIsSchedulesFetched(false);
                    setIsScheduling(false);
                    getScheduleList();
                    }}
                  >Ok
                  </Button>
                </div>
                      )}
              headerSlot="Delete Completed Successfully"
              isOpen={isDeleteScheduleCompletedModalOpen}
              onDismiss={() => setIsDeleteScheduleCompletedModalOpen(false)}
            />
          )}
        </>
      )}
    </main>
  );
};

export default StoreHierarchyScheduleMain;
