/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { Input, Loading } from '@nike/frame-component-library';
import { Close, Delete } from '@nike/nike-design-system-icons';
import countries from 'i18n-iso-countries';
import React, { useState, useEffect, createRef } from 'react';
import { useSelector } from 'react-redux';
import ReactSelect from 'react-select';

import { loadState } from '../../../utils/local-storage';
import { scsDefinitionGet, scsBulkedit } from '../../../utils/service-calls/scs';
import { getStores } from '../../../utils/service-calls/sls';
import { Geo, toGeo } from '../../../utils/static/scs-geo-iso-map';
import { isUserAllowed } from '../../../utils/tab-permissions';
import Modal from '../../ccm/Modal';
import {
  ButtonBlack,
  ButtonRed,
  TableRowClick
 } from '../../reusable';

import FilterOverlay from './filters';
import Selected from './selected';

import './bulkedit.css';

const scsKeyGroups = ['SCS.ALL', 'SCS.ASSIST', 'SCS.LINK', 'SCS.NPOS', 'SCS.OTHER', 'SCS.POS', 'SCS.PROMOTE', 'SCS.RL', 'SCS.RISE', 'SCS.SFS', 'SCS.SIM', 'SCS.SCO'];

const securityGroupMap = new Map([
  ['POS Settings', 'SCS.POS'],
  ['AIC Settings', 'SCS.ASSIST'],
  ['Assist Settings', 'SCS.ASSIST'],
  ['Nike+ Link Settings', 'SCS.LINK'],
  ['NSP Settings', 'SCS.ASSIST'],
  ['RISE Settings', 'SCS.RISE'],
  ['SCORE Settings', 'SCS.SFS'],
  ['SIM Settings', 'SCS.SIM'],
  ['Self Checkout Settings', 'SCS.SCO'],
  ['NPOS Settings', 'SCS.NPOS'],
  ['Retail Launchpad', 'SCS.RL'],
  ['Promote App Settings', 'SCS.PROMOTE'],
  ['Other Settings', 'SCS.OTHER'],
]);

const fields = [
  'id',
  'storeNumber',
  'name',
  'company',
  'facilityType',
  'businessConcept',
  'storeConcept',
  'address.country',
  'address.city',
  'address.state',
  'region',
  'offerings.name',
  'brand',
  'storeServices.serviceGroup',
  'storeServices.serviceSubGroup',
  'storeStatus',
];
const columns = [
  {
    accessor: (data) => data.key,
    Header: 'Config Key',
    id: 'Config Key',
  },
  {
    accessor: (data) => data.scopeType,
    Header: 'Scope Type',
    id: 'Scope Type',
  },
  {
    accessor: (data) => data.scope,
    Header: 'Scope',
    id: 'Scope',
  }
];

const config = require('../../../config')();

const globalCommonSelectorOption = {
  data: [
    {
      id: 'GLOBAL',
      label: 'Global',
      scope: 'GLOBAL',
    },
  ],
  scopeType: 'GLOBAL',
};

// let selectedCommonSelectorOption = null;

const SCSBulkEdit = () => {
  const { accessToken } = loadState('auth');
  const [stores, setStores] = useState([]);
  const [filteredStores, setFilteredStores] = useState([]);
  const [selectionSearch, setSelectionSearch] = useState('');
  const [selectedSearch, setSelectedSearch] = useState('');
  const [isFilterOverlayVisible, setIsFilterOverlayVisible] = useState(false);
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [selectedScsRegionOption, setSelectedScsRegionOption] = useState(null);
  const [selectedScsScopeOption, setSelectedScsScopeOption] = useState(null);
  const [selectedScsRegionScopeOption, setSelectedScsRegionScopeOption] = useState(null);
  const [selectedKey, setSelectedKey] = useState(null);
  const [selectedCommonSelectorOption, setSelectedCommonSelectorOption] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [filteredKeyDefinitions, setFilteredKeyDefinitions] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [allKeyDefinitions, setAllKeyDefinitions] = useState([]);
  const [failedConfigs, setFailedConfigs] = useState([]);
  const [successfulConfigs, setSuccessfulConfigs] = useState([]);
  const [isBulkUpdateSuccessModalOpen, setIsBulkUpdateSuccessModalOpen] = useState(false);
  const [isBulkUpdateFailureModalOpen, setIsBulkUpdateFailureModalOpen] = useState(false);
  const [isBulkUpdateComplete, setIsBulkUpdateComplete] = useState(true);
  const [isRegionChangeModalOpen, setIsRegionChangeModalOpen] = useState(false);
  const [isScopeChangeModalOpen, setIsScopeChangeModalOpen] = useState(false);
  const userInfo = useSelector((state) => state.authorizationReducer.auth);
  const [filteringCondition, setFilteringCondition] = useState({
    country: { label: 'All Countries', value: '' },
    facilityType: { label: 'All Facility Types', value: '' },
    region: { label: 'Select a Region', value: '' },
    regionSelected: false,
    search: '',
    state: { label: 'All States', value: '' },
    storeStatus: { label: 'All Store Statuses', value: '' },
});

const updateFilterRef = createRef();

const getLabel = (key) => {
  switch (key) {
    case 'country':
      return 'All Countries';
    case 'facilityType':
      return 'All Facility Types';
    case 'region':
      return 'All Regions';
    case 'state':
      return 'All States';
    case 'storeStatus':
      return 'All Store Statuses';
    default:
      return '';
  }
};

// check if all the values in filteringCondition are empty
const isFilterEmpty = () => {
  const keys = Object.keys(filteringCondition);
  for (let i = 0; i < keys.length; i += 1) {
    // check if the value is not undefined and not empty
    if (filteringCondition[keys[i]].value !== '' && filteringCondition[keys[i]].value !== undefined) {
      return false;
    }
  }
  return true;
};

const cancelFilter = (key) => {
  const newFilteringCondition = { ...filteringCondition, [key]: { label: getLabel(key), value: '' } };
  setFilteringCondition(newFilteringCondition);
  updateFilterRef.current(key, { label: getLabel(key), value: '' }); // Example call to updateFilter
};

  const updateRegion = (regionOption) => {
    setSelectedScsRegionScopeOption(regionOption);
    if (selectedScsRegionOption && selectedScsRegionOption.value !== regionOption.value && selectedCommonSelectorOption) {
      setIsRegionChangeModalOpen(true);
    } else {
      setSelectedScsRegionOption(regionOption);
    }
  };

  const updateScsScope = (scopeOption) => {
    setSelectedScsRegionScopeOption(scopeOption);
    if (selectedScsScopeOption && selectedScsScopeOption.value !== scopeOption.value && selectedCommonSelectorOption) {
      setIsScopeChangeModalOpen(true);
    } else {
      setSelectedScsScopeOption(scopeOption);
    }
  };

  const changeRegion = () => {
    setIsRegionChangeModalOpen(false);
    setSelectedScsRegionOption(selectedScsRegionScopeOption);
    setSelectedKey(null);
    setSelectedCommonSelectorOption(null);
  };

  const changeScope = () => {
    setIsScopeChangeModalOpen(false);
    setSelectedScsScopeOption(selectedScsRegionScopeOption);
    setSelectedKey(null);
    setSelectedCommonSelectorOption(null);
  };

  const applyFilter = () => {
    setIsFilterOverlayVisible(true);
 };

 const clearAllFilters = () => {
   setIsFilterApplied(false);
};

 const filterApplied = () => {
   setIsFilterApplied(true);
};

 const closeFilterOverlay = () => {
   setIsFilterOverlayVisible(false);
};

const updateStores = (filteredStoresFromFilter) => {
  if (selectedScsScopeOption && selectedScsScopeOption.value === 'STORE') {
  setFilteredStores(filteredStoresFromFilter);
  }
};

const handleFilteringConditionChange = (newFilteringCondition) => {
  setFilteringCondition(newFilteringCondition);
};

  const checkIfAlreadyExists = (commonSelectorOption) => {
    let exists = false;
    if (selectedCommonSelectorOption && selectedCommonSelectorOption.data) {
      selectedCommonSelectorOption.data.forEach((option) => {
        if (option.id === commonSelectorOption.id) {
          exists = true;
        }
      });
    }
    return exists;
  };

  const updateCommonSelector = (commonSelectorOption) => {
    setSelectedKey(commonSelectorOption.id);
    if (!selectedScsScopeOption) return;

    const scopeType = selectedScsScopeOption.value;
    const commonSelector = selectedCommonSelectorOption || { data: [], scopeType };

    if (checkIfAlreadyExists(commonSelectorOption)) return;

    let newOption = {
      id: commonSelectorOption.id,
      label: scopeType === 'STORE' ? `${commonSelectorOption.storeNumber} - ${commonSelectorOption.name}` : commonSelectorOption.name,
      scope: commonSelectorOption.id,
    };

    if (scopeType === 'STORE') {
      newOption = {
        ...newOption,
        brand: commonSelectorOption.brand,
        country: commonSelectorOption.address.country,
        geo: toGeo(commonSelectorOption),
        scope: `${commonSelectorOption.address.country}-${commonSelectorOption.storeNumber}`,
      };
    } else if (scopeType === 'COUNTRY') {
      newOption = {
        ...newOption,
        brand: commonSelectorOption.brand,
        country: commonSelectorOption.country,
        geo: commonSelectorOption.geo,
        scope: commonSelectorOption.country,
      };
    } else if (scopeType === 'GLOBAL') {
      newOption = {
        ...newOption,
        id: 'GLOBAL',
        label: 'Global',
        scope: 'GLOBAL',
      };
    }

    commonSelector.data.push(newOption);
    setSelectedCommonSelectorOption(commonSelector);
  };

  const updateSelectedCommonSelectorOption = (commonSelectorOption) => {
    // check if commonSelectorOption.data is empty, then set it to null
    if (commonSelectorOption.data.length === 0) {
      setSelectedCommonSelectorOption(null);
    } else {
    setSelectedCommonSelectorOption(commonSelectorOption);
    }
  };

  const filterScsKeyOptionsForRegion = filteredKeyDefinitions.map((key) => ({
    label: key.key,
    type: key.type,
    value: key.id,
  }));

  const updateScsKey = (keyOption) => {
    // get the existing keys from SelectedKeys
    // check if the keyOption is already present in the selectedKeys
    // if not, add it to the selectedKeys
    const keyExists = selectedKeys.some((key) => key.label === keyOption.label);
    if (!keyExists) {
      setSelectedKeys((prevSelectedKeys) => [...prevSelectedKeys, { ...keyOption, type: keyOption.type, value: '' }]);
    }
  };

  const handleInputChange = (value) => {
    setInputValue(value);
    if (value.length >= 3) {
      const filteredDefinitions = allKeyDefinitions.filter((key) => key.key.toLowerCase().includes(value.toLowerCase()));
      setFilteredKeyDefinitions(filteredDefinitions);
    } else {
      setFilteredKeyDefinitions([]);
    }
  };

  const handleValueChange = (index, newValue) => {
    // set the value of the key in the selectedKeys
    const updatedKeys = selectedKeys.map((key, i) => (i === index ? { ...key, value: newValue } : key));
    setSelectedKeys(updatedKeys);
  };

  const handleMenuOpen = () => {
    setInputValue('');
  };

  const removeKey = (index) => {
    const updatedKeys = [...selectedKeys];
    updatedKeys.splice(index, 1);
    setSelectedKeys(updatedKeys);
  };

  const updateBulkConfigs = async () => {
    setIsBulkUpdateComplete(false);
    const body = selectedKeys.map((key) => {
      const { scopeType } = selectedCommonSelectorOption || globalCommonSelectorOption;
      const scopeInfo = selectedCommonSelectorOption?.data || globalCommonSelectorOption.data;
      return {
        key: key.label,
        scopeInfo: scopeInfo.map((scope) => ({
          hierarchy: selectedScsScopeOption?.value === 'GLOBAL'
            ? null
            : {
            brand: scope.brand || '',
            country: scope.country || '',
            geo: scope.geo || '',
          },
          scope: scope.scope,
        })),
        scopeType,
        value: key.value,
      };
    });
    try {
        const token = accessToken; // Adjust as needed to get the token
        const scsRegion = selectedScsRegionOption?.value || 'use1';
        const response = await scsBulkedit(token, body, scsRegion);
        setFailedConfigs(response.body.failure);
        setSuccessfulConfigs(response.body.success);
        setIsBulkUpdateSuccessModalOpen(true);
      } catch (error) {
        setIsBulkUpdateFailureModalOpen(true);
      } finally {
        setIsBulkUpdateComplete(true);
      }
  };

  const getSelectionList = () => {
    if (selectedScsScopeOption?.value === 'STORE') {
      const selectionStores = isFilterApplied ? filteredStores : stores;
      // filter the stores based on the search input
      return selectionStores.filter((store) => {
        const storeName = store.name.toLowerCase();
        const storeNumber = store.storeNumber.toLowerCase();
        return storeName.includes(selectionSearch.toLowerCase()) || storeNumber.includes(selectionSearch.toLowerCase());
      });
    } else if (selectedScsScopeOption?.value === 'COUNTRY') {
        const countryCodeMap = stores.reduce((acc, store) => {
          acc[store.address.country] = {
            brand: 'NIKE',
            country: store.address.country,
            geo: toGeo(store),
            id: store.address.country,
            name: store.address.country === 'TUR' ? 'Türkiye' : countries.getName(store.address.country, 'en')
          };
          return acc;
        }, {});

        const countryMap = Object.keys(countryCodeMap).map((key) => countryCodeMap[key]);
        return countryMap.filter((country) => {
          const countryName = country.name.toLowerCase();
          return countryName.includes(selectionSearch.toLowerCase());
        });
    } else if (selectedScsScopeOption?.value === 'GEO') {
      return [
        { id: Geo.ASIA, name: 'Asia' },
        { id: Geo.EM, name: 'Emerging Markets' },
        { id: Geo.EUROPE, name: 'Europe' },
        { id: Geo.ILS, name: 'ILS' },
        { id: Geo.JPN, name: 'Japan' },
        { id: Geo.NA, name: 'North America' },
        { id: Geo.RUB, name: 'Russia' },
        { id: Geo.SA, name: 'South America' }
      ];
    } else if (selectedScsScopeOption?.value === 'GLOBAL') {
      return [{ id: 'GLOBAL', name: 'Global' }];
    }
    return [];
  };

  const handleModalClose = () => {
    setIsBulkUpdateSuccessModalOpen(false);
    setIsBulkUpdateFailureModalOpen(false);
    setSelectedKeys([]);
    setFailedConfigs([]);
    setSelectedCommonSelectorOption(null);
    setIsEditMode(false);
    setSelectedSearch('');
    setSelectionSearch('');
  };

  const getBoxLabel = () => {
    if (selectedScsScopeOption?.value === 'STORE') {
      return 'Stores';
    } else if (selectedScsScopeOption?.value === 'COUNTRY') {
      return 'Countries';
    } else if (selectedScsScopeOption?.value === 'GEO') {
      return 'Geos';
    } else if (selectedScsScopeOption?.value === 'GLOBAL') {
      return 'Global';
    } else {
      return '';
    }
  };

  useEffect(() => {
    async function fetchData() {
      const fieldQuery = fields.join(',');
      try {
        const response = await getStores(accessToken, fieldQuery, '');
        setStores(response);
      } catch (err) {
        console.error(err);
      }
    }
    fetchData();
  }, [accessToken]);

  useEffect(() => {
    const fetchAllKeyDefinitions = async () => {
      const selectedRegion = selectedScsRegionOption?.value || 'use1';
      const data = await scsDefinitionGet(accessToken, selectedRegion);
      const { objects: definitions } = data?.body;
      const scsGroups = scsKeyGroups.filter((group) => isUserAllowed(userInfo.groups, group));

      definitions.forEach((definition) => {
        definition.group = definition.group || 'Other Settings';
      });

      const filteredDefinitions = definitions.filter((definition) => scsGroups.includes('SCS.ALL') || scsGroups.includes(securityGroupMap.get(definition.group)));

      setAllKeyDefinitions(filteredDefinitions);
    };

    fetchAllKeyDefinitions();
  }, [selectedScsRegionOption, accessToken]);

  return (
    <main className="ncss-col-sm-12 parent-edit-class">
      {
        stores.length === 0 ? (
          <Loading />
        ) : (
          stores.length > 0 && (
            <article className="ncss-col-sm-12">
              <article className="ncss-col-sm-12">
                <article className="ncss-col-sm-12">
                  <article className="ncss-col-sm-12">
                    <article className="ncss-col-sm-12 pb2-sm">
                      <article className="ncss-col-sm-2 va-sm-b ta-sm-c"> </article>
                      <article className="ncss-col-sm-4 va-sm-b ta-sm-c">
                        AWS Region
                        <ReactSelect
                          classNamePrefix="react-select"
                          clearable={false}
                          id="scsRegion"
                          isDisabled={isEditMode}
                          options={config.scsRegionOptions}
                          placeholder="Select a Region"
                          styles={{
                      container: (styles) => ({ ...styles, zIndex: 100 }),
                      control: (styles) => ({
                        ...styles,
                        borderColor: 'lightgrey',
                        padding: '0px 0px 0px 3px',
                      }),
                    }}
                          value={selectedScsRegionOption}
                          onChange={(regionOption) => updateRegion(regionOption)}
                        />
                      </article>
                      <article className="ncss-col-sm-4 va-sm-b ta-sm-c">
                        Scope:
                        <ReactSelect
                          classNamePrefix="react-select"
                          clearable={false}
                          id="scsScopes"
                          isDisabled={isEditMode}
                          options={config.scsScopeOptions}
                          placeholder="Select a Scope"
                          styles={{
                      container: (styles) => ({ ...styles, zIndex: 100 }),
                      control: (styles) => ({
                        ...styles,
                        borderColor: 'lightgrey',
                        padding: '0px 0px 0px 3px',
                      }),
                    }}
                          value={selectedScsScopeOption}
                          onChange={(scopeOption) => updateScsScope(scopeOption)}
                        />
                      </article>
                      <article className="ncss-col-sm-2 va-sm-t ta-sm-r">
                        { selectedScsRegionOption && selectedScsScopeOption && selectedScsScopeOption.value === 'STORE' && !isEditMode && (
                        <ButtonBlack label="Filters" onClick={applyFilter} />
                  )}
                      </article>
                    </article>
                  </article>
                </article>
              </article>
              { !isEditMode && (
                <div className="show-filter-container">
                  {!isFilterEmpty() ? (<> Selected Filters: </>) : <></>}
                  {Object.entries(filteringCondition).map(([key, value]) => (
                  value.value !== '' && key !== 'regionSelected' && key !== 'search' ? (
                    <div className="bulk-chip">
                      {`${value.label}`}
                      <span className="bulk-close-icon"><Close name="Close" size="s" style={{ flexDirection: 'row', outline: 'none', verticalAlign: 'bottom' }} onClick={() => cancelFilter(key)} /></span>
                    </div>
                    ) : null
                    ))}
                </div>
              )}
              { !isEditMode && selectedScsRegionScopeOption && selectedScsRegionScopeOption.value !== 'GLOBAL' ? (
                <article className="ncss-col-sm-12 va-sm-t ta-sm-r">
                  <article className="ncss-col-sm-12 bulk-edit-article-height">
                    {selectedScsScopeOption
              && selectedScsRegionOption && (
                <article className="ncss-col-sm-12">
                  <br />
                  <article className="ncss-col-sm-12">
                    <section className="ncss-col-sm-6 va-sm-t ta-sm-c">
                      <label htmlFor="search">{`Available ${getBoxLabel()} - ${getSelectionList(selectedScsScopeOption).length}`}</label>
                    </section>
                    <section className="ncss-col-sm-6 va-sm-t ta-sm-c">
                      <label htmlFor="search">{`Selected ${getBoxLabel()} - ${selectedCommonSelectorOption?.data.length || 0}`}</label>
                    </section>
                  </article>
                  { (selectedScsScopeOption.value === 'STORE' || selectedScsScopeOption.value === 'COUNTRY') && (
                  <article className="ncss-col-sm-12 pb2-sm">
                    <section className="ncss-col-sm-6 va-sm-t ta-sm-l">
                      <Input
                        label=""
                        placeholder={`Search available ${getBoxLabel()}`}
                        style={{ zIndex: 1 }}
                        type="text"
                        value={selectionSearch}
                        onChange={({ target: { value } }) => setSelectionSearch(value)}
                      />
                    </section>
                    <section className="ncss-col-sm-6 va-sm-t ta-sm-l">
                      <Input
                        label=""
                        placeholder={`Search selected ${getBoxLabel()}`}
                        style={{ zIndex: 1 }}
                        type="text"
                        value={selectedSearch}
                        onChange={({ target: { value } }) => setSelectedSearch(value)}
                      />
                    </section>
                  </article>
                  )}
                  <article className="ncss-col-sm-12">
                    <article className="ncss-col-sm-6 va-sm-t ta-sm-l">
                      <div className="key-list">
                        {
                        getSelectionList(selectedScsScopeOption).map((selection) => (
                          <div
                            key={selection.id}
                            className={`key-item ${
                              selectedKey === selection.id ? 'selected' : ''
                            }`}
                            onClick={() => updateCommonSelector(selection)}
                          >
                            {selectedScsScopeOption.value === 'STORE'
                            ? `${selection.storeNumber} - ${selection.name} - ${selection.facilityType} - ${selection.company}`
                            : selection.name}
                          </div>
                        ))
                      }
                      </div>
                    </article>
                    <article className="ncss-col-sm-6 va-sm-t ta-sm-l">
                      <div className="key-list">
                        { selectedCommonSelectorOption && (
                        <Selected
                          input={selectedCommonSelectorOption}
                          search={selectedSearch}
                          onUpdate={updateSelectedCommonSelectorOption}
                        />
                        )}
                      </div>
                    </article>
                  </article>
                </article>
              )}
                  </article>
                  { selectedScsRegionOption && selectedScsScopeOption && (
                  <article className="ncss-col-sm-12">
                    <ButtonBlack
                      isDisabled={selectedCommonSelectorOption === null}
                      label="Next"
                      onClick={() => { setIsEditMode(true); }}
                    />
                  </article>
              )}
                </article>
          ) : (
            <>
              <article className={selectedScsScopeOption?.value === 'GLOBAL' ? 'ncss-col-sm-12 va-sm-t ta-sm-c' : 'ncss-col-sm-12 va-sm-t ta-sm-r'}>
                <article className="ncss-col-sm-12 bulk-edit-article-height">
                  {selectedScsScopeOption
              && selectedScsRegionOption && (
                <article className="ncss-col-sm-12">
                  <br />
                  <article className="ncss-col-sm-12 pb2-sm">
                    { selectedScsScopeOption && selectedScsScopeOption.value !== 'GLOBAL' && (
                    <section className="ncss-col-sm-6 va-sm-t ta-sm-l">
                      <label className="ncss-col-sm-12 va-sm-t ta-sm-c" htmlFor="search">{`Selected ${getBoxLabel() } - ${selectedCommonSelectorOption?.data.length || 0}`}</label>
                      <Input
                        label=""
                        placeholder={`Search selected ${getBoxLabel()}`}
                        style={{ zIndex: 1 }}
                        type="text"
                        value={selectedSearch}
                        onChange={({ target: { value } }) => setSelectedSearch(value)}
                      />
                    </section>
                    )}
                    <section className={selectedScsScopeOption.value === 'GLOBAL' ? 'ncss-col-sm-8 va-sm-t ta-sm-c' : 'ncss-col-sm-6 va-sm-t ta-sm-l'}>
                      <label className="ncss-col-sm-12 va-sm-t ta-sm-c" htmlFor="search">{`Selected Config Keys - ${selectedKeys.length}`}</label>
                      <ReactSelect
                        classNamePrefix="react-select"
                        id="scsKey"
                        inputValue={inputValue}
                        isDisabled={false}
                        noOptionsMessage={() => (inputValue.length < 3 ? 'Type at least 3 characters' : 'No options')}
                        options={filterScsKeyOptionsForRegion}
                        placeholder="Search for Config Key"
                        styles={{
            container: (containerStyles) => ({ ...containerStyles, zIndex: 10 }),
            control: (controlStyles) => ({
              ...controlStyles,
              borderColor: 'lightgrey',
              fontSize: '14px',
              padding: '0px 0px 0px 3px',
            }),
          }}
                        value={selectedKey}
                        onChange={(keyOption) => updateScsKey(keyOption)}
                        onInputChange={handleInputChange}
                        onMenuOpen={handleMenuOpen}
                      />
                    </section>
                  </article>
                  <article className="ncss-col-sm-12">
                    { selectedScsScopeOption && selectedScsScopeOption.value !== 'GLOBAL' && (
                    <article className={selectedScsScopeOption.value === 'GLOBAL' ? 'ncss-col-sm-8 va-sm-t ta-sm-l' : 'ncss-col-sm-6 va-sm-t ta-sm-l'}>
                      <div className="key-list">
                        { selectedCommonSelectorOption && (
                        <Selected
                          input={selectedCommonSelectorOption}
                          search={selectedSearch}
                          update={false}
                          onUpdate={updateSelectedCommonSelectorOption}
                        />
                        )}
                      </div>
                    </article>
                  )}
                    <article className={selectedScsScopeOption.value === 'GLOBAL' ? 'ncss-col-sm-8 va-sm-t ta-sm-l' : 'ncss-col-sm-6 va-sm-t ta-sm-l'}>
                      {/* Centered Outer Box */}
                      <div className="key-list">
                        {
                          selectedKeys.map((key, index) => (
                            <div key={key.label} className="edit-key-item">
                              <article className="ncss-col-sm-12">
                                <div className="d-sm-flx flx-ai-sm-c">
                                  <article className="ncss-col-sm-5 va-sm-c">
                                    <span className="edit-key-item key-name headline-5">{key.label}</span>
                                  </article>
                                  <article className="ncss-col-sm-6 va-sm-t ta-sm-l">
                                    {key.type === 'STRING' && (
                                    <Input
                                      className="value-input"
                                      placeholder="String Value"
                                      type="text"
                                      value={key.value}
                                      onChange={(e) => handleValueChange(index, e.target.value)}
                                    />
                              )}
                                    {key.type === 'INTEGER' && (
                                    <Input
                                      className="value-input"
                                      placeholder="Integer Value"
                                      type="number"
                                      value={key.value}
                                      onChange={(e) => handleValueChange(index, e.target.value)}
                                    />
                              )}
                                    {key.type === 'BOOLEAN' && (
                                    <ReactSelect
                                      classNamePrefix="react-select"
                                      clearable={false}
                                      id="scsRegion"
                                      options={['true', 'false'].map((value) => ({ label: value, value }))}
                                      placeholder="Boolean Value"
                                      styles={{
                                        container: (styles) => ({ ...styles }),
                                        control: (styles) => ({
                                          ...styles,
                                          borderColor: 'lightgrey',
                                          fontSize: '14px',
                                          padding: '0px 0px 0px 3px',
                                        }),
                                      }}
                                      value={key.value ? { label: key.value, value: key.value } : null}
                                      onChange={(e) => handleValueChange(index, e.value)}
                                    />
                              )}
                                  </article>
                                  <article className="ncss-col-sm-1 va-sm-c ta-sm-c">
                                    <Delete name="Delete" size="m" onClick={() => removeKey(index)} />
                                  </article>
                                </div>
                              </article>
                            </div>
                          ))
                        }
                      </div>
                    </article>
                  </article>
                </article>
              )}
                </article>
                { selectedScsRegionOption && selectedScsScopeOption && (
                  <article className="ncss-col-sm-12">
                    { selectedScsScopeOption.value !== 'GLOBAL' && (
                    <ButtonBlack
                      isDisabled={selectedCommonSelectorOption === null}
                      label="Previous"
                      onClick={() => { setIsEditMode(false); }}
                    />
                    )}
                    { isBulkUpdateComplete ? (
                      <ButtonBlack
                        isDisabled={selectedKeys.length === 0}
                        label="Update"
                        onClick={() => { updateBulkConfigs(); }}
                      />
                    ) : (
                      <ButtonBlack
                        isDisabled
                        label="Loading..."
                        onClick={() => { updateBulkConfigs(); }}
                      />
)}
                  </article>
              )}
              </article>
            </>
          ) }
            </article>
          )
        )
      }
      <div>
        <FilterOverlay
          clearAllFilters={clearAllFilters}
          filterApplied={filterApplied}
          filteringCondition={filteringCondition}
          isFilterOverlayVisible={isFilterOverlayVisible}
          stores={stores}
          updateFilterRef={updateFilterRef}
          onClose={closeFilterOverlay}
          onFilterUpdate={updateStores}
          onFilteringConditionChange={handleFilteringConditionChange}
        />
      </div>

      <Modal
        buttonName="OK"
        isOpen={isBulkUpdateSuccessModalOpen}
        title=""
        onClose={() => handleModalClose()}
      >
        {
          failedConfigs.length === 0 ? (
            <p>Bulk Update Successful!!</p>
          ) : (
            <>
              { successfulConfigs.length === 0 ? (<p>Bulk Update Failed!!</p>) : (<p>Partial Success!!</p>)}
              <br />
              <p>Failed Configs:</p>
              <TableRowClick
                showPagination
                columns={columns}
                data={failedConfigs}
                defaultPageSize={failedConfigs.length % 10 === 0 ? 10 : failedConfigs.length % 10}
              />
            </>
          )
        }
      </Modal>

      <Modal
        buttonName="OK"
        isOpen={isBulkUpdateFailureModalOpen}
        title=""
        onClose={() => handleModalClose()}
      >
        <p>Bulk Update Failed!!</p>
      </Modal>

      <Modal
        buttonName="Cancel"
        isOpen={isRegionChangeModalOpen}
        title="Confirm Region Change"
        onClose={() => setIsRegionChangeModalOpen(false)}
      >
        <p>Are you sure you want to change region?</p>
        <p>{`Note: Switching region will lead to clearing of selected ${getBoxLabel()}`}</p>
        <ButtonRed confirm={false} label="Confirm" onClick={changeRegion} />
      </Modal>

      <Modal
        buttonName="Cancel"
        isOpen={isScopeChangeModalOpen}
        title="Confirm Scope Change"
        onClose={() => setIsScopeChangeModalOpen(false)}
      >
        <p>Are you sure you want to change scope?</p>
        <p>{`Note: Switching scope will lead to clearing of selected ${getBoxLabel()}`}</p>
        <ButtonRed confirm={false} label="Confirm" onClick={changeScope} />
      </Modal>

    </main>
  );
};

export default SCSBulkEdit;
