import React from 'react';

import StoreHierarchyContextProvider from './StoreHierarchyContextProvider';
import StoreHierarchyPage from './StoreHierarchyPage';

const StoreHierarchyApp = () => {
  const isSchedulesFetched = false;
  const scheduleEditList = [];
  const notCompletedSchedules = [];
  const isScheduling = false;

  return (
    <StoreHierarchyContextProvider>
      <StoreHierarchyPage isSchedulesFetched={isSchedulesFetched} isScheduling={isScheduling} scheduleEditList={scheduleEditList} scheduleList={notCompletedSchedules} />
    </StoreHierarchyContextProvider>
  );
};

export default StoreHierarchyApp;
