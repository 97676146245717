export default `
input[type="text"] {
  min-width: 20em;
}

.store-hierarchy-page {
  margin-left: 1.2em;
  margin-right: 1.2em;
}

.hierarchy-body {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 80%;
}

.hierarchy-column {
  display: flex;
  flex-direction: column;
  width: 25%;
  padding-left: 0.3em;
  padding-right: 0.3em;
  gap: 0.3em;
  border-left: 1px solid var(--eds-color-border-disabled);
}

.hierarchy-column:last-child {
  border-right: 1px solid var(--eds-color-border-disabled);
}

.hierarchy-node-default,
.hierarchy-node-selected {
  padding: 0 0.2em 0 0.2em !important;
  cursor: pointer !important;
}

.hierarchy-node-default {
  background-color: var(--eds-color-background-default) !important;
  border: 1px solid var(--eds-color-border-primary) !important;
}

.hierarchy-node-selected {
  background-color: var(--eds-color-black) !important;
  color: var(--eds-color-text-link-disable) !important;
  border: 1px solid var(--eds-color-border-active) !important;
}

.hierarchy-node-default * {
  color: var(--eds-color-text-default) !important;
}

.hierarchy-node-selected * {
  color: var(--eds-color-white) !important;
}

.hierarchy-node-default:hover {
  background-color: var(--eds-color-background-hover) !important;
  border: 1px solid var(--eds-color-border-hover) !important;
}

.hierarchy-node-default:hover * {
  color: var(--eds-color-text-hover) !important;
}

.text-center {
  text-align: center;
}
.button-right {
  position: fixed;
  bottom: 20px; /* Adjust as needed */
  right: 20px; /* Adjust as needed */
  z-index: 1000; /* Ensure it is above other elements */
}

.table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 30px;
}

.table th, .table td {
  border: 1px solid #ddd;
  padding: 8px;
}

.table th {
  background-color: #f2f2f2;
  text-align: left;
  font-weight: bold;
} 

.footer-buttons {
  display: flex;
  justify-content: center;
  gap: 10px; /* Optional: Adds space between buttons */
}
  

`;
