{
  "host": "https://retailconfigui-prod.nikecloud.com",
  "redirectUrl": "https://retailconfigui-prod.nikecloud.com/auth",
  "authUrl": "https://api.aegis.nikecloud.com/v1/prod/authorize",
  "rosHost": "https://retailoperations.retailconfigui-prod.nikecloud.com/retailoperations",
  "hierarchyHost": "https://store-hierarchy-scheduler.membershipservices-prod.nikecloud.com/retail",
  "storeviewsHost": "https://storeviews-regional-us-east-1.risedomain-prod.nikecloud.com",
  "scsRegionOptions": [
    { "value": "use1", "label": "US East" },
    { "value": "sae1", "label": "South America" },
    { "value": "euw1", "label": "Europe" },
    { "value": "apse1", "label": "Asia Pacific" }
  ],
  "scsScopeOptions": [
    { "value": "GLOBAL", "label": "GLOBAL" },
    { "value": "COUNTRY", "label": "COUNTRY" },
    { "value": "STORE", "label": "STORE" }
  ]
}