/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  Button, TextField,
 Link, Snack, Snackbar, Modal
} from '@nike/eds';
import { DatePickerStyled } from '@nike/nike-design-system-components';
import { Delete } from '@nike/nike-design-system-icons';
import { Default, isSome, match } from '@nike/rcf-fp';
import PropTypes from 'prop-types';
import React, {
 useContext, useState, useEffect
} from 'react';
import { v4 as uuidv4 } from 'uuid';

import { requestWrapperHierarchySchedules } from '../../../utils/service-calls/reusable';
import { createScheduledChanges } from '../services/hierarchy-service/index.js';

import {
  Regions,
  Territories,
  Districts,
  Stores,
  HierarchyColumn
} from './hierarchy-columns';
import { StoreHierarchyContext } from './StoreHierarchyContextProvider';

const StoreHierarchy = ({ isScheduling, scheduleList, scheduleEditList }) => {
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [eventName, setEventName] = useState(null);

  const {
    currentSheduleChangesList,
    accessToken,
    setCurrentSheduleChangesList,
    setSuccessMessage,
    setError
  } = useContext(StoreHierarchyContext);

  const handleNextClick = () => {
    setIsAddModalOpen(true);
  };

  const handleSaveForLater = async () => {
    // iterate over scheduleStatus and modify the scheduleStatus to WORK_IN_PROGRESS

    // remove createdAt from the all the items in the list
    currentSheduleChangesList.forEach((item) => {
      delete item.createdAt;
    });

    const groupId = uuidv4();
    currentSheduleChangesList.forEach((item) => {
      item.scheduleStatus = 'WORK_IN_PROGRESS';
      item.scheduledDate = `${selectedDate}T00:00:00Z`;
      if (scheduleEditList && scheduleEditList.length > 0) {
        item.groupId = scheduleEditList[0].groupId;
      } else {
        item.groupId = groupId;
      }
      item.eventName = eventName;
    });
    const response = await createScheduledChanges(currentSheduleChangesList, accessToken);
    if (response === 201) {
      setCurrentSheduleChangesList([]);
      setIsAddModalOpen(false);
      setSuccessMessage('Scheduled changes saved successfully');
    } else {
      setError('Error saving scheduled changes');
    }
  };

  const handleSaveAndSchedule = async () => {
    // iterate over scheduleStatus and modify the scheduleStatus to PENDING
    // remove createdAt from the all the items in the list
    currentSheduleChangesList.forEach((item) => {
      delete item.createdAt;
    });

    const groupId = uuidv4();
    currentSheduleChangesList.forEach((item) => {
      item.scheduleStatus = 'PENDING';
      if (scheduleEditList && scheduleEditList.length > 0) {
        item.groupId = scheduleEditList[0].groupId;
      } else {
        item.groupId = groupId;
      }
      item.eventName = eventName;
      item.scheduledDate = `${selectedDate}T00:00:00Z`;
    });
    const response = await createScheduledChanges(currentSheduleChangesList, accessToken);
    if (response === 201) {
      setCurrentSheduleChangesList([]);
      setIsAddModalOpen(false);
      setSuccessMessage('Scheduled changes saved successfully');
    } else {
      setError('Error saving scheduled changes');
    }
  };

  const handleDateChange = (date) => {
    setSelectedDate(date); // Update the selected date
  };

  useEffect(() => {
    if (currentSheduleChangesList === null || currentSheduleChangesList.length === 0) {
      setIsAddModalOpen(false);
    }
  }, [currentSheduleChangesList]);

  const handleDelete = (entityId) => {
    const updatedList = currentSheduleChangesList.filter((item) => item.entityId !== entityId);
    setCurrentSheduleChangesList(updatedList);
    if (updatedList === null || updatedList.length === 0) {
      setIsAddModalOpen(false);
    }
    const scheduleToDelete = currentSheduleChangesList.find((item) => item.entityId === entityId);
    if (scheduleToDelete.scheduleId) {
      requestWrapperHierarchySchedules(
        accessToken,
        `/store_hierarchy_schedule_ui/v1/${scheduleToDelete.scheduleId}`,
        'DELETE'
      )
        .then((response) => {
          if (response === 204) {
            return 'Schedule deleted successfully';
          } else {
            return null;
          }
        })
        .catch(() => {
          setError('Error deleting schedule');
        });
    }
  };

  useEffect(() => {
    if (scheduleEditList && scheduleEditList.length > 0) {
      // to check date in sheduleEditList and set the selected date and eventName
      const date = new Date(scheduleEditList[0].scheduledDate);
      let month = (date.getMonth() + 1).toString(); // getMonth() returns 0-11, so add 1
      month = month.padStart(2, '0'); // Add leading zero if single digit
      const formattedDate = `${date.getFullYear()}-${month}-${date.getDate()}`;
      setSelectedDate(formattedDate);
      setEventName(scheduleEditList[0].eventName);
      // add currentScheduleEditList plus scheduleEditList
      if (currentSheduleChangesList && currentSheduleChangesList.length > 0) {
        setCurrentSheduleChangesList([...scheduleEditList, ...currentSheduleChangesList]);
      } else {
        setCurrentSheduleChangesList(scheduleEditList);
      }
       }
  },
   // eslint-disable-next-line react-hooks/exhaustive-deps
   []);

return (

  <>
    <div className="hierarchy-body">
      <HierarchyColumn component={<Regions />} />
      <HierarchyColumn component={<Territories isScheduling={isScheduling} scheduleEditList={scheduleEditList} scheduleList={scheduleList} />} />
      <HierarchyColumn component={<Districts isScheduling={isScheduling} scheduleEditList={scheduleEditList} scheduleList={scheduleList} />} />
      <HierarchyColumn lastColumn component={<Stores isScheduling={isScheduling} scheduleEditList={scheduleEditList} scheduleList={scheduleList} />} />

    </div>
    {isScheduling && currentSheduleChangesList !== null && currentSheduleChangesList.length !== 0 && (
      <div className="button-right">
        <Button type="button" onClick={handleNextClick}>
          Next
        </Button>
      </div>
    )}
    {isAddModalOpen && (
    <Modal
      footerSlot={(
        <div className="footer-buttons">
          <Button disabled={!(selectedDate && eventName)} onClick={handleSaveForLater}>Save for Later</Button>
          <Button disabled={!(selectedDate && eventName)} onClick={handleSaveAndSchedule}>Save and Schedule</Button>
        </div>
    )}
      headerSlot="Scheduled Changes"
      isOpen={isAddModalOpen}
      onDismiss={() => setIsAddModalOpen(false)}
    >

      <TextField
        id="event-name"
        label="Event Name"
        name="event-name"
        placeholder="Enter event name"
        value={eventName}
        onChange={({ target: { value } }) => setEventName(value)}
      />
      <DatePickerStyled
        ariaLive="polite"
        autoComplete="off"
        dateFormat="yyyy-MM-dd"
        error={false}
        errorMessage="Error message"
        iconTitle="Show date picker"
        id="id"
        label="Scheduled Date"
        locale="US"
        max=""
        min=""
        name="name"
        placeholder="Select a date"
        readOnly={false}
        requiredIndicator="*"
        shouldUseFallback={false}
        supportText="Note: The changes will be applied to the hierarchy on this selected date."
        value={selectedDate}
        onChange={({ target: { value: newValue } }) => handleDateChange(newValue)}
      />
      <span />
      {selectedDate && (
      <table className="table">
        <thead>
          <tr>
            <th>Entity Type</th>
            <th>Name</th>
            <th>Scheduled Date</th>
            <th>Existing Value</th>
            <th>New Value</th>
            <th>Operation</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {currentSheduleChangesList.map((item) => (
            <tr key={item.scheduleId}>
              <td>{item.entityType}</td>
              <td>{item.payload.name}</td>
              <td>{selectedDate}</td>
              <td>{item.existingLocation}</td>
              <td>{item.destinationLocation}</td>
              <td>{item.operation}</td>
              <td>
                <Delete size="s" title="Delete" onClick={() => handleDelete(item.entityId)} />
              </td>
            </tr>
            ))}
        </tbody>
      </table>
) }

    </Modal>
    )}
  </>
);
};

const Error = () => {
  const { error, setLoadPage, setError } = useContext(StoreHierarchyContext);
  return (
    <Snackbar>
      {isSome(error) && (
      <Snack id="page-error" status="error" onDismiss={() => setError(null)}>
        <p>An error occurred - please&nbsp;
          <Link
            as="button"
            onClick={() => {
            setLoadPage(true);
            setError(null);
          }}
          >reload
          </Link>&nbsp;and try again.
        </p>
        <p>If the issue persists please contact support.</p>
      </Snack>
    )}
    </Snackbar>
  );
};

const Success = () => {
  const { successMessage, setSuccessMessage } = useContext(StoreHierarchyContext);
  return (
    <Snackbar>
      {isSome(successMessage) && (
      <Snack
        autoDismissDuration={5000}
        id="page-success"
        status="success"
        onDismiss={() => setSuccessMessage(null)}
      >
        <p>{successMessage}</p>
      </Snack>
    )}
    </Snackbar>
  );
};

const StoreHierarchyContainer = ({ isScheduling, scheduleList, scheduleEditList }) => {
  const { error } = useContext(StoreHierarchyContext);
  return match(true)(
    [() => isSome(error), () => (<Error />)],
    [Default, () => (
      <>
        <Success />
        <StoreHierarchy isScheduling={isScheduling} scheduleEditList={scheduleEditList} scheduleList={scheduleList} />
      </>
    )],
  );
};

StoreHierarchyContainer.propTypes = {
  isScheduling: PropTypes.bool.isRequired,
};
StoreHierarchy.propTypes = {
  isScheduling: PropTypes.bool.isRequired,
  scheduleEditList: PropTypes.arrayOf(
    PropTypes.shape({
      brand: PropTypes.string.isRequired,
      createdAt: PropTypes.string.isRequired,
      createdBy: PropTypes.string.isRequired,
      eventName: PropTypes.string.isRequired,
      groupId: PropTypes.string.isRequired,
      payload: PropTypes.shape({}).isRequired,
      region: PropTypes.string.isRequired,
      scheduledDate: PropTypes.string.isRequired,
      scheduleId: PropTypes.string.isRequired,
      scheduleStatus: PropTypes.string.isRequired
    })
  ).isRequired,
  scheduleList: PropTypes.arrayOf(
    PropTypes.shape({
      brand: PropTypes.string.isRequired,
      createdAt: PropTypes.string.isRequired,
      createdBy: PropTypes.string.isRequired,
      eventName: PropTypes.string.isRequired,
      groupId: PropTypes.string.isRequired,
      payload: PropTypes.shape({}).isRequired,
      region: PropTypes.string.isRequired,
      scheduledDate: PropTypes.string.isRequired,
      scheduleId: PropTypes.string.isRequired,
      scheduleStatus: PropTypes.string.isRequired
    })
  ).isRequired,
};

export default StoreHierarchyContainer;
