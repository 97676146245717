{
  "host": "http://localhost:3000",
  "redirectUrl": "http://localhost:3000/auth",
  "authUrl": "https://api.aegis.nikecloud.com/v1/qa/authorize",
  "rosHost": "https://retailoperations.retailconfigui-test.nikecloud.com/retailoperations",
  "hierarchyHost": "https://store-hierarchy-scheduler.membershipservices-test.nikecloud.com/retail",
  "storeviewsHost": "https://storeviews-regional-us-east-1.risedomain-test.nikecloud.com",
  "scsRegionOptions": [
    { "value": "use1", "label": "US East" }
  ],
  "scsScopeOptions": [
    { "value": "GLOBAL", "label": "GLOBAL" },
    { "value": "COUNTRY", "label": "COUNTRY" },
    { "value": "STORE", "label": "STORE" }
  ]
}
